// @ts-nocheck
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { User } from 'firebase/auth';
import { Timestamp } from 'firebase/firestore';
import { auth } from './firebase';

interface UserProfile {
  firstName: string | null;
  lastName: string | null;
  organization: string | null;
  role: string | null;
  learningInstructions: string | null;
}

interface AuthState {
  user: User | null;
  profile: UserProfile | null;
  subscription: string | null;
  trialEndsAt: Timestamp | null;
  initialized: boolean;
  setUser: (user: User | null) => void;
  setProfile: (profile: UserProfile | null) => void;
  setSubscription: (plan: string | null) => void;
  setTrialEndsAt: (date: Timestamp | null) => void;
  setInitialized: (initialized: boolean) => void;
  updateSettings: (settings: Partial<UserProfile>) => void;
}

export const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      user: null,
      profile: null,
      subscription: null,
      trialEndsAt: null,
      initialized: false,
      setUser: (user) => set({ user }),
      setProfile: (profile) => set({ profile }),
      setSubscription: (subscription) => set({ subscription }),
      setTrialEndsAt: (trialEndsAt) => set({ trialEndsAt }),
      setInitialized: (initialized) => set({ initialized }),
      updateSettings: (settings) => set((state) => ({
        profile: state.profile ? { ...state.profile, ...settings } : settings
      })),
    }),
    {
      name: 'auth-storage',
      partialize: (state) => ({
        profile: state.profile,
        subscription: state.subscription,
        trialEndsAt: state.trialEndsAt ? {
          seconds: state.trialEndsAt.seconds,
          nanoseconds: state.trialEndsAt.nanoseconds
        } : null,
      }),
      merge: (persistedState: any, currentState) => ({
        ...currentState,
        ...persistedState,
        trialEndsAt: persistedState.trialEndsAt ? 
          new Timestamp(
            persistedState.trialEndsAt.seconds,
            persistedState.trialEndsAt.nanoseconds
          ) : null,
      }),
    }
  )
);

// Initialize auth state listener after Firebase is initialized
setTimeout(() => {
  auth.onAuthStateChanged((user) => {
    useAuthStore.getState().setUser(user);
    useAuthStore.getState().setInitialized(true);
  });
}, 0);

// For backward compatibility
export const useAppStore = useAuthStore;