// @ts-nocheck
import React, { useState, useEffect, useRef } from 'react';
import { ChatContainer } from './components/chat/ChatContainer';
import { Canvas } from './components/canvas/Canvas';
import { ResizablePanel } from './components/chat/ResizablePanel';
import { TopNav } from './components/layout/TopNav';
import { LandingView } from './components/chat/LandingView';
import { AuthPage } from './components/auth/AuthPage';
import { SettingsModal } from './components/settings/SettingsModal';
import { useSession } from './lib/hooks/useSession';
import { useChatListStore } from './lib/stores/chatListStore';
import { ChatSidebar } from './components/chat/ChatSidebar';
import { cn } from './lib/utils';

function App() {
  const { user, initialized } = useSession();
  const { activeChat, sidebarVisible, setSidebarVisible } = useChatListStore();
  const [showLanding, setShowLanding] = useState(true);
  const [showSettings, setShowSettings] = useState(false);
  const sidebarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (activeChat) {
      const timer = setTimeout(() => {
        setShowLanding(false);
      }, 100);
      return () => clearTimeout(timer);
    } else {
      setShowLanding(true);
    }
  }, [activeChat]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (sidebarVisible && 
          sidebarRef.current && 
          !sidebarRef.current.contains(event.target as Node)) {
        setSidebarVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [sidebarVisible, setSidebarVisible]);

  if (!initialized) {
    return (
      <div className="flex min-h-screen items-center justify-center bg-charcoal-950">
        <div className="animate-pulse text-electric-blue">Loading...</div>
      </div>
    );
  }

  if (!user) {
    return <AuthPage />;
  }

  return (
    <div className="flex flex-col h-screen bg-charcoal-950">
      <TopNav onShowSettings={() => setShowSettings(true)} />
      <main className="flex-1 relative overflow-hidden">
        <div className="flex h-full">
          {/* Sidebar */}
          <div
            ref={sidebarRef}
            className={cn(
              "absolute inset-y-0 left-0 z-30 transform",
              "transition-transform duration-300 ease-in-out",
              sidebarVisible ? "translate-x-0" : "-translate-x-full"
            )}
          >
            <ChatSidebar />
          </div>

          {/* Main Content */}
          <div className="flex-1">
            {/* Landing View with slide-up animation */}
            <div
              className={cn(
                "absolute inset-0 z-20 bg-charcoal-950",
                "transition-all duration-500 ease-in-out",
                !showLanding && "transform -translate-y-full opacity-0"
              )}
            >
              <LandingView />
            </div>

            {/* Main Chat Interface */}
            <div
              className={cn(
                "h-full transition-opacity duration-500",
                showLanding ? "opacity-0" : "opacity-100"
              )}
            >
              <ResizablePanel
                leftPanel={<ChatContainer />}
                rightPanel={<Canvas />}
                initialLeftWidth={40}
                minLeftWidth={30}
                maxLeftWidth={70}
              />
            </div>
          </div>
        </div>
      </main>

      <SettingsModal 
        isOpen={showSettings} 
        onClose={() => setShowSettings(false)} 
      />
    </div>
  );
}

export default App;