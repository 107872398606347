// @ts-nocheck
import React from 'react';
import { PlusCircle, Trash2, MessageSquare } from 'lucide-react';
import { useChatListStore } from '@/lib/stores/chatListStore';
import { useCanvasStore } from '@/lib/stores/canvasStore';
import { CanvasTypeSelector } from './CanvasTypeSelector';
import { cn } from '@/lib/utils';
import { format } from 'date-fns';

export function ChatSidebar() {
  const { chats, activeChat, createChat, deleteChat, setActiveChat } = useChatListStore();
  const { type, setType } = useCanvasStore();

  // Sort chats by updatedAt in descending order (most recent first)
  const sortedChats = [...chats].sort((a, b) => 
    b.updatedAt.getTime() - a.updatedAt.getTime()
  );

  return (
    <div className="w-64 h-full bg-charcoal-900 border-r border-charcoal-800 flex flex-col">
      <div className="p-4 space-y-3 border-b border-charcoal-800">
        <button
          onClick={createChat}
          className={cn(
            "w-full flex items-center justify-center gap-2 px-4 py-2",
            "bg-electric-blue hover:bg-electric-blue/90",
            "text-charcoal-900 font-medium rounded-lg",
            "transition-colors duration-200"
          )}
        >
          <PlusCircle className="w-5 h-5" />
          New Chat
        </button>

        <div className="flex items-center gap-2">
          <span className="text-sm text-charcoal-400">View:</span>
          <CanvasTypeSelector value={type} onChange={setType} />
        </div>
      </div>

      <div className="flex-1 overflow-y-auto p-2 space-y-2">
        {sortedChats.map((chat) => (
          <div
            key={chat.id}
            className={cn(
              "group relative p-3 rounded-lg cursor-pointer",
              "hover:bg-charcoal-800/50",
              "transition-colors duration-200",
              activeChat === chat.id && "bg-charcoal-800"
            )}
            onClick={() => setActiveChat(chat.id)}
          >
            <div className="flex items-start gap-3">
              <div className="relative flex-shrink-0">
                <MessageSquare className="w-5 h-5 text-electric-blue" />
                {chat.requirements?.length > 0 && (
                  <div className={cn(
                    "absolute -top-1 -right-1",
                    "min-w-[16px] h-4 px-1",
                    "flex items-center justify-center",
                    "bg-electric-blue rounded-full",
                    "text-[10px] font-medium text-charcoal-900"
                  )}>
                    {chat.requirements.length}
                  </div>
                )}
              </div>
              <div className="flex-1 min-w-0">
                <h3 className="text-sm font-medium text-white truncate">
                  {chat.title}
                </h3>
                <p className="text-xs text-charcoal-400">
                  {format(new Date(chat.updatedAt), 'MMM d, yyyy')}
                </p>
              </div>
            </div>

            <button
              onClick={(e) => {
                e.stopPropagation();
                deleteChat(chat.id);
              }}
              className={cn(
                "absolute right-2 top-1/2 -translate-y-1/2",
                "p-1.5 rounded-md",
                "text-charcoal-400 hover:text-red-400",
                "opacity-0 group-hover:opacity-100",
                "transition-all duration-200"
              )}
            >
              <Trash2 className="w-4 h-4" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}