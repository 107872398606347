// @ts-nocheck
import React, { useRef, useEffect } from 'react';
import { Logo } from '../brand/Logo';
import { useCanvasStore } from '@/lib/stores/canvasStore';
import { useRequirementsStore } from '@/lib/stores/requirementsStore';
import { useChatListStore } from '@/lib/stores/chatListStore';
import { cn } from '@/lib/utils';
import { UserMenu } from './UserMenu';
import { ChevronDown, Sparkles, Menu } from 'lucide-react';
import { RequirementsList } from '../canvas/RequirementsList';

interface TopNavProps {
  onShowSettings: () => void;
}

export function TopNav({ onShowSettings }: TopNavProps) {
  const { title, leftPanelWidth } = useCanvasStore();
  const requirements = useRequirementsStore((state) => state.requirements);
  const [showRequirements, setShowRequirements] = React.useState(false);
  const requirementsRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const { toggleSidebar } = useChatListStore();

  // Handle click outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (showRequirements && 
          requirementsRef.current && 
          buttonRef.current && 
          !requirementsRef.current.contains(event.target as Node) &&
          !buttonRef.current.contains(event.target as Node)) {
        setShowRequirements(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [showRequirements]);

  const displayTitle = title || 'Learning Program Design';

  return (
    <nav className="flex-shrink-0 bg-charcoal-900/95 backdrop-blur-sm border-b border-charcoal-800/50 sticky top-0 z-50">
      <div className="h-16 px-4">
        <div className="flex items-center h-full">
          {/* Left side: Menu and Logo */}
          <div className="flex items-center gap-4 w-[200px]">
            <button
              onClick={toggleSidebar}
              className="p-2 text-charcoal-400 hover:text-white rounded-lg hover:bg-charcoal-800"
            >
              <Menu className="w-5 h-5" />
            </button>
            <Logo />
          </div>

          {/* Center: Canvas Title */}
          <div className="flex-1 flex justify-center">
            <div 
              className="relative flex items-center transition-all duration-300 ease-in-out"
              style={{ 
                marginLeft: `${Math.max(0, leftPanelWidth - 20)}%`,
                width: 'auto'
              }}
            >
              <button
                ref={buttonRef}
                onClick={() => setShowRequirements(!showRequirements)}
                className={cn(
                  "group flex items-center gap-3 px-4 py-2 rounded-lg",
                  "hover:bg-charcoal-800/50",
                  "transition-all duration-200"
                )}
              >
                <div className={cn(
                  "p-2 rounded-lg",
                  "bg-gradient-to-r from-purple-500/10 to-blue-500/10",
                  "border border-purple-500/20 group-hover:border-purple-500/30",
                  "transition-colors duration-200"
                )}>
                  <Sparkles className="w-5 h-5 text-purple-400" />
                </div>
                
                <div className="flex flex-col items-start">
                  <h2 className={cn(
                    "text-xl font-bold",
                    "bg-gradient-to-r from-purple-400 via-electric-blue to-purple-400",
                    "bg-[length:200%_100%] bg-clip-text text-transparent",
                    "animate-shimmer",
                    !title && "opacity-50"
                  )}>
                    {displayTitle}
                  </h2>
                  
                  {requirements.length > 0 && (
                    <span className="text-xs text-charcoal-400">
                      {requirements.length} Requirements Gathered
                    </span>
                  )}
                </div>

                <ChevronDown className={cn(
                  "w-5 h-5 text-charcoal-400",
                  "transition-transform duration-200",
                  showRequirements && "transform rotate-180"
                )} />
              </button>
            </div>
          </div>

          {/* Right side: User Menu */}
          <div className="w-[200px] flex justify-end items-center gap-4">
            <UserMenu onShowSettings={onShowSettings} />
          </div>
        </div>
      </div>

      {/* Requirements Panel */}
      <div 
        ref={requirementsRef}
        className={cn(
          "absolute top-full left-0 right-0",
          "bg-charcoal-900/95 backdrop-blur-sm",
          "border-b border-charcoal-800/50",
          "transform transition-all duration-300 ease-in-out",
          showRequirements ? "opacity-100 translate-y-0" : "opacity-0 -translate-y-4 pointer-events-none"
        )}
      >
        <div className="max-w-7xl mx-auto">
          <RequirementsList />
        </div>
      </div>
    </nav>
  );
}