// @ts-nocheck
import OpenAI from 'openai';
import { PATH_NAMING_PROMPT } from './prompts';

const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

export async function generateCanvasName(context: string[]): Promise<string> {
  try {
    console.log('Generating canvas name for context:', context);

    const response = await openai.chat.completions.create({
      model: 'gpt-4-0125-preview',
      messages: [
        { role: 'system', content: PATH_NAMING_PROMPT },
        { 
          role: 'user', 
          content: `Please generate a title based on the following context:\n\n${context.join('\n')}`
        }
      ],
      response_format: { type: 'json_object' }
    });

    const content = response.choices[0]?.message?.content;
    console.log('AI response:', content);

    if (!content) {
      console.log('No content in response, using default title');
      return 'New Conversation';
    }

    try {
      const { title } = JSON.parse(content);
      console.log('Parsed title:', title);
      return title || 'New Conversation';
    } catch (error) {
      console.error('Failed to parse canvas name:', error);
      return 'New Conversation';
    }
  } catch (error) {
    console.error('Failed to generate canvas name:', error);
    return 'New Conversation';
  }
}