// @ts-nocheck
import { useEffect } from 'react';
import { useAuthStore } from '../store';
import { initializeUserProfile } from '../services/user/userProfile';

export function useSession() {
  const { user, initialized } = useAuthStore();

  useEffect(() => {
    const initializeSession = async () => {
      if (user && initialized) {
        console.log('Initializing session for existing user:', user.uid);
        await initializeUserProfile(user);
      }
    };

    initializeSession();
  }, [user, initialized]);

  return { user, initialized };
}