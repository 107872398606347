// @ts-nocheck
import React, { useEffect, useRef } from 'react';
import { useChatListStore } from '@/lib/stores/chatListStore';
import { useChat } from '@/lib/hooks/useChat';
import { useAuthStore } from '@/lib/store';
import { cn } from '@/lib/utils';
import { ArrowRight, FileText, Link2, Sparkles } from 'lucide-react';
import { UserAvatar } from '../layout/UserAvatar';
import { Message } from '@/lib/types/chat';

interface MessageListProps {
  messages: Message[];
}

export function MessageList({ messages }: MessageListProps) {
  const { sendMessage } = useChat();
  const user = useAuthStore((state) => state.user);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const renderContent = (content: string) => {
    // Check for URL references
    const urlRefRegex = /\[(.*?)\]/g;
    const parts = content.split(urlRefRegex);

    return parts.map((part, i) => {
      if (i % 2 === 1) { // URL reference
        return (
          <span
            key={i}
            className={cn(
              "inline-flex items-center gap-1 px-2 py-0.5 rounded",
              "bg-electric-blue/10 text-electric-blue",
              "border border-electric-blue/20"
            )}
          >
            <Link2 className="w-3 h-3" />
            {part}
          </span>
        );
      }
      return part;
    });
  };

  return (
    <div className="flex-1 overflow-y-auto p-4 space-y-4">
      {messages.map((message) => (
        <div key={message.id} className="space-y-2">
          <div className={cn(
            "flex",
            message.role === 'user' ? "justify-end" : "justify-start"
          )}>
            {message.role === 'user' ? (
              <div className="max-w-[80%] space-y-1">
                <div className="flex items-start gap-3 justify-end">
                  <div className="space-y-1">
                    <div className="rounded-lg py-2 px-3 bg-charcoal-800/50 text-electric-blue">
                      <p className="whitespace-pre-wrap text-sm">
                        {renderContent(message.content)}
                      </p>
                    </div>
                    {message.files && message.files.length > 0 && (
                      <div className="flex flex-wrap gap-2 justify-end">
                        {message.files.map((file, index) => (
                          <div
                            key={index}
                            className={cn(
                              "flex items-center gap-2 px-2 py-1",
                              "bg-charcoal-800/50 rounded-lg",
                              "text-xs text-charcoal-300"
                            )}
                          >
                            <FileText className="w-3 h-3" />
                            <span className="truncate max-w-[150px]">{file.name}</span>
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <UserAvatar />
                </div>
              </div>
            ) : (
              <div className="flex gap-3 max-w-[90%]">
                <div className="flex-shrink-0 w-8 h-8 rounded-full bg-electric-blue/10 flex items-center justify-center">
                  <Sparkles className="w-5 h-5 text-electric-blue" />
                </div>
                <div 
                  className={cn(
                    "prose prose-sm prose-invert max-w-none",
                    "rounded-lg py-2 px-3",
                    message.error ? "text-red-400" : "text-charcoal-300",
                    "bg-transparent"
                  )}
                  dangerouslySetInnerHTML={{ __html: message.content || '...' }}
                />
              </div>
            )}
          </div>

          {message.role === 'assistant' && message.suggestions && (
            <div className="pl-12 space-y-1">
              {message.suggestions.map((suggestion, index) => (
                <button
                  key={index}
                  onClick={() => sendMessage(suggestion)}
                  className={cn(
                    "group flex items-center gap-2 w-full text-left",
                    "px-3 py-2 rounded-lg",
                    "bg-charcoal-800/30 hover:bg-charcoal-700/30",
                    "border border-charcoal-700/30 hover:border-electric-blue/20",
                    "text-sm text-charcoal-400 hover:text-electric-blue",
                    "transition-all duration-200"
                  )}
                >
                  <span className="line-clamp-1">{suggestion}</span>
                  <ArrowRight 
                    className={cn(
                      "w-4 h-4 flex-shrink-0",
                      "opacity-0 group-hover:opacity-100 -translate-x-2 group-hover:translate-x-0",
                      "transition-all duration-200"
                    )}
                  />
                </button>
              ))}
            </div>
          )}
        </div>
      ))}
      <div ref={messagesEndRef} />
    </div>
  );
}