// @ts-nocheck
import Anthropic from '@anthropic-ai/sdk';
import { AIMessage, AIResponse } from '../types';

const anthropic = new Anthropic({
  apiKey: import.meta.env.VITE_ANTHROPIC_API_KEY,
});

export async function chatWithAnthropic(messages: AIMessage[]): Promise<AIResponse> {
  try {
    const formattedMessages = messages.map(msg => ({
      role: msg.role === 'system' ? 'user' : msg.role,
      content: msg.content,
    }));

    const response = await anthropic.messages.create({
      model: 'claude-3-opus-20240229',
      max_tokens: 1024,
      messages: formattedMessages,
    });

    return {
      content: response.content[0].text,
      model: 'claude-3',
      suggestions: []
    };
  } catch (error) {
    console.error('Anthropic API error:', error);
    throw error;
  }
}