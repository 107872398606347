// @ts-nocheck
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

interface CanvasState {
  type: 'learning-program' | 'content-map';
  title: string | null;
  rating: CanvasRating | null;
  leftPanelWidth: number;
}

interface CanvasStore extends CanvasState {
  setType: (type: CanvasState['type']) => void;
  setTitle: (title: string) => void;
  setRating: (rating: CanvasRating) => void;
  setLeftPanelWidth: (width: number) => void;
  reset: () => void;
}

export const useCanvasStore = create<CanvasStore>()(
  persist(
    (set) => ({
      type: 'learning-program',
      title: null,
      rating: null,
      leftPanelWidth: 40,
      setType: (type) => set({ type, title: null, rating: null }),
      setTitle: (title) => set({ title }),
      setRating: (rating) => set({ rating }),
      setLeftPanelWidth: (leftPanelWidth) => set({ leftPanelWidth }),
      reset: () => set({ title: null, rating: null })
    }),
    {
      name: 'canvas-storage'
    }
  )
);