// @ts-nocheck
import { AIMessage, AIResponse } from './types';

const OFFLINE_RESPONSE = `I apologize, but I'm currently operating in offline mode due to connection issues. Here are some things you can try:

1. Check your internet connection
2. Refresh the page
3. Try again in a few minutes

Your previous conversations and settings are still available and will sync once connectivity is restored.`;

export const getOfflineResponse = (messages: AIMessage[]): AIResponse => {
  return {
    content: OFFLINE_RESPONSE,
    model: messages[0]?.model || 'gpt-4-0125-preview',
    suggestions: [
      'Check network connectivity',
      'Try a different browser',
      'Contact support if the issue persists'
    ]
  };
};