// @ts-nocheck
import { User } from 'firebase/auth';
import { doc, getDoc, setDoc, serverTimestamp } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuthStore } from '../../store';
import { initializeUserProfile } from '../user/userProfile';

export const handleUserAuth = async (user: User | null) => {
  try {
    if (!user) {
      console.log('No user, clearing auth store');
      useAuthStore.getState().setUser(null);
      useAuthStore.getState().setProfile(null);
      useAuthStore.getState().setSubscription(null);
      useAuthStore.getState().setTrialEndsAt(null);
      return null;
    }

    console.log('Handling auth for user:', user.uid);
    
    // Get user document reference
    const userRef = doc(db, 'users', user.uid);
    const docSnap = await getDoc(userRef);
    
    if (!docSnap.exists()) {
      console.log('Creating new user document');
      // Create new user document with default profile structure
      const userData = {
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
        photoURL: user.photoURL,
        profile: {
          firstName: null,
          lastName: null,
          organization: null,
          role: null,
          learningInstructions: null,
        },
        subscription: {
          status: 'trial',
          plan: null,
          trialEndsAt: serverTimestamp(),
        },
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
        lastLoginAt: serverTimestamp(),
      };

      console.log('New user data:', userData);
      await setDoc(userRef, userData);
    } else {
      console.log('Updating existing user document');
      // Update last login time
      await setDoc(userRef, {
        lastLoginAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      }, { merge: true });
    }
    
    // Update auth store with user
    console.log('Setting user in auth store');
    useAuthStore.getState().setUser(user);
    
    // Initialize user profile and related data
    console.log('Initializing user profile');
    await initializeUserProfile(user);
    
    return true;
  } catch (error) {
    console.error('Error handling user auth:', error);
    // Set basic user info even if there's an error
    useAuthStore.getState().setUser(user);
    return null;
  }
};