// @ts-nocheck
import React from 'react';
import { X, Edit2, FileText, Link2 } from 'lucide-react';
import { useRequirementsStore } from '@/lib/stores/requirementsStore';
import { useChatListStore } from '@/lib/stores/chatListStore';
import { cn } from '@/lib/utils';

export function RequirementsList() {
  const { activeChat, updateChat } = useChatListStore();
  const { requirements, removeRequirement, updateRequirement } = useRequirementsStore();
  const [editingId, setEditingId] = React.useState<string | null>(null);
  const [editContent, setEditContent] = React.useState('');

  const handleEdit = (requirement: { id: string; content: string }) => {
    setEditingId(requirement.id);
    setEditContent(requirement.content);
  };

  const handleSave = () => {
    if (editingId && editContent.trim()) {
      // Update both stores
      updateRequirement(editingId, editContent.trim());
      if (activeChat) {
        updateChat(activeChat, chat => ({
          ...chat,
          requirements: chat.requirements.map(req =>
            req.id === editingId ? { ...req, content: editContent.trim() } : req
          )
        }));
      }
      setEditingId(null);
      setEditContent('');
    }
  };

  const handleRemove = (id: string) => {
    // Remove from both stores
    removeRequirement(id);
    if (activeChat) {
      updateChat(activeChat, chat => ({
        ...chat,
        requirements: chat.requirements.filter(req => req.id !== id)
      }));
    }
  };

  const handleUrlClick = (url: string) => {
    if (url) {
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <div className="space-y-2 max-h-[70vh] overflow-y-auto p-4">
      {requirements.map((requirement) => (
        <div
          key={`req-${requirement.id}`}
          className={cn(
            "relative group p-3 rounded-lg",
            "bg-charcoal-800/50 border border-charcoal-700/50",
            "hover:border-electric-blue/20",
            "transition-all duration-200"
          )}
        >
          <div className="flex items-start justify-between gap-2">
            <div className="flex-1">
              {requirement.category === 'document' && requirement.document && (
                <div className="flex items-center gap-2 mb-2">
                  <div className={cn(
                    "p-1.5 rounded",
                    "bg-electric-blue/10 text-electric-blue",
                    "border border-electric-blue/20"
                  )}>
                    {requirement.document.type === 'url' ? (
                      <Link2 
                        className="w-4 h-4 cursor-pointer"
                        onClick={() => requirement.document?.metadata?.url && 
                          handleUrlClick(requirement.document.metadata.url)}
                      />
                    ) : (
                      <FileText className="w-4 h-4" />
                    )}
                  </div>
                  <span 
                    className={cn(
                      "text-sm font-medium text-electric-blue",
                      requirement.document.type === 'url' && "cursor-pointer hover:underline"
                    )}
                    onClick={() => {
                      if (requirement.document?.type === 'url' && requirement.document?.metadata?.url) {
                        handleUrlClick(requirement.document.metadata.url);
                      }
                    }}
                  >
                    {requirement.document.name}
                  </span>
                </div>
              )}

              {editingId === requirement.id ? (
                <div className="mt-1">
                  <textarea
                    value={editContent}
                    onChange={(e) => setEditContent(e.target.value)}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter' && !e.shiftKey) {
                        e.preventDefault();
                        handleSave();
                      }
                    }}
                    className={cn(
                      "w-full bg-transparent border rounded-md p-2",
                      "focus:outline-none focus:ring-1 focus:ring-electric-blue",
                      "text-sm resize-none"
                    )}
                    rows={2}
                    autoFocus
                  />
                  <div className="flex justify-end mt-2 gap-2">
                    <button
                      onClick={() => setEditingId(null)}
                      className="text-xs text-charcoal-400 hover:text-white"
                    >
                      Cancel
                    </button>
                    <button
                      onClick={handleSave}
                      className="text-xs text-electric-blue hover:text-electric-blue/80"
                    >
                      Save
                    </button>
                  </div>
                </div>
              ) : (
                <p className="text-sm text-charcoal-300 whitespace-pre-wrap">
                  {requirement.content}
                </p>
              )}
            </div>

            <div className={cn(
              "flex items-center gap-2",
              "opacity-0 group-hover:opacity-100",
              "transition-opacity duration-200"
            )}>
              {requirement.category !== 'document' && (
                <button
                  onClick={() => handleEdit(requirement)}
                  className="p-1 text-charcoal-400 hover:text-white"
                >
                  <Edit2 className="w-4 h-4" />
                </button>
              )}
              <button
                onClick={() => handleRemove(requirement.id)}
                className="p-1 text-charcoal-400 hover:text-red-400"
              >
                <X className="w-4 h-4" />
              </button>
            </div>
          </div>
        </div>
      ))}

      {requirements.length === 0 && (
        <div className="text-center text-charcoal-400 py-4">
          No requirements gathered yet. Start chatting to build your learning program.
        </div>
      )}
    </div>
  );
}