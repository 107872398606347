// @ts-nocheck
import React from 'react';
import { Github, Facebook } from 'lucide-react';
import { signInWithGoogle, signInWithGithub, signInWithFacebook } from '@/lib/auth';

export const AuthButtons = () => {
  return (
    <div className="flex gap-3 w-full">
      <button
        onClick={signInWithGoogle}
        className="flex-1 flex items-center justify-center gap-2 px-3 py-2.5 bg-white hover:bg-gray-50 text-gray-800 rounded-lg transition-colors border border-gray-200 font-medium text-sm"
      >
        <img 
          src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg" 
          alt="Google" 
          className="w-5 h-5"
        />
        Google
      </button>
      
      <button
        onClick={signInWithGithub}
        className="flex-1 flex items-center justify-center gap-2 px-3 py-2.5 bg-[#24292F] hover:bg-[#24292F]/90 text-white rounded-lg transition-colors font-medium text-sm"
      >
        <Github className="w-5 h-5" />
        GitHub
      </button>
      
      <button
        onClick={signInWithFacebook}
        className="flex-1 flex items-center justify-center gap-2 px-3 py-2.5 bg-[#1877F2] hover:bg-[#1877F2]/90 text-white rounded-lg transition-colors font-medium text-sm"
      >
        <Facebook className="w-5 h-5" />
        Facebook
      </button>
    </div>
  );
};