// @ts-nocheck
import React from 'react';
import { MessageList } from './MessageList';
import { ChatInput } from './ChatInput';
import { useChatListStore } from '@/lib/stores/chatListStore';
import { useFileUpload } from '@/lib/hooks/useFileUpload';
import { useChatSync } from '@/lib/hooks/useChatSync';
import { cn } from '@/lib/utils';

export function ChatContainer() {
  const { activeChat, chats } = useChatListStore();
  const activeMessages = activeChat ? chats.find(c => c.id === activeChat)?.messages : [];
  const {
    isDragging,
    setIsDragging,
    addFiles,
    files,
    removeFile,
    clearFiles
  } = useFileUpload();

  // Sync chat data across components
  const chatSync = useChatSync();

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(false);
    
    if (e.dataTransfer.files) {
      addFiles(e.dataTransfer.files);
    }
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (e.currentTarget.contains(e.relatedTarget as Node)) return;
    setIsDragging(false);
  };

  if (!activeChat) return null;

  return (
    <div 
      className="flex flex-col h-full bg-charcoal-950 relative"
      onDrop={handleDrop}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
    >
      {isDragging && (
        <div className="absolute inset-0 z-50 flex items-center justify-center">
          <div className="absolute inset-0 bg-charcoal-950/80" />
          <div className={cn(
            "relative p-8 rounded-lg",
            "bg-charcoal-800/90",
            "border-2 border-dashed border-electric-blue",
            "text-center space-y-2",
            "animate-pulse"
          )}>
            <p className="text-xl font-medium text-electric-blue">Drop files here</p>
            <p className="text-sm text-charcoal-300">
              Supported formats: PDF, DOC, DOCX, XLS, XLSX, CSV, TXT
            </p>
          </div>
        </div>
      )}

      <MessageList messages={activeMessages || []} />
      
      <ChatInput 
        files={files}
        onRemoveFile={removeFile}
        onClearFiles={clearFiles}
        onAddFiles={addFiles}
      />
    </div>
  );
}