// @ts-nocheck
import React from 'react';

interface QuickActionProps {
  icon: string;
  label: string;
  onClick: () => void;
}

export const QuickAction: React.FC<QuickActionProps> = ({ icon, label, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="flex items-center gap-3 w-full p-3 bg-charcoal-800 hover:bg-charcoal-700 rounded-lg text-left transition-colors group border border-charcoal-700"
    >
      <span className="text-xl">{icon}</span>
      <span className="text-sm text-charcoal-300 group-hover:text-white transition-colors">
        {label}
      </span>
    </button>
  );
};