// @ts-nocheck
import React from 'react';
import { X } from 'lucide-react';
import { FileWithPreview } from '@/lib/types/files';
import { cn } from '@/lib/utils';

interface FilePreviewProps {
  files: FileWithPreview[];
  onRemove: (index: number) => void;
}

export function FilePreview({ files, onRemove }: FilePreviewProps) {
  if (files.length === 0) return null;

  return (
    <div className="flex flex-wrap gap-2 mb-4">
      {files.map((file, index) => (
        <div
          key={index}
          className={cn(
            "relative group",
            "flex items-center gap-2 px-3 py-2",
            "bg-charcoal-800/50 rounded-lg",
            "border border-charcoal-700/50",
            "hover:border-electric-blue/50",
            "transition-all duration-200"
          )}
        >
          <span className="text-xl">{file.preview}</span>
          <span className="text-sm text-charcoal-300 truncate max-w-[150px]">
            {file.file.name}
          </span>
          <button
            onClick={() => onRemove(index)}
            className={cn(
              "ml-2 p-1 rounded-full",
              "text-charcoal-400 hover:text-red-400",
              "hover:bg-red-500/10",
              "transition-colors duration-200"
            )}
          >
            <X className="w-4 h-4" />
          </button>
        </div>
      ))}
    </div>
  );
}