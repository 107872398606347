// @ts-nocheck
import { AIMessage, AIModel } from './types';
import { getAIResponse } from './providers';

const SKILL_TREE_PROMPT = `Analyze the conversation and generate a comprehensive skill tree. The response should be in this format:

{
  "skillTree": {
    "Skills": [
      {
        "skill": "Main Skill",
        "subskills": ["Subskill 1", "Subskill 2"],
        "level": "beginner|intermediate|advanced",
        "dependencies": ["Prerequisite Skill"]
      }
    ]
  }
}`;

export async function generateSkillTree(messages: AIMessage[], model: AIModel) {
  try {
    // Clear existing skill tree and show loading state
    const skillTreeMessages = [
      {
        id: 'system',
        role: 'system',
        content: SKILL_TREE_PROMPT,
        timestamp: new Date(),
        model
      },
      ...messages
    ];

    const response = await getAIResponse(skillTreeMessages, model);
    return JSON.parse(response.content).skillTree;
  } catch (error) {
    console.error('Failed to generate skill tree:', error);
    throw error;
  }
}