// @ts-nocheck
import { SearchResult } from '@/lib/types/search';

export async function searchContent(searchTerm: string, searchType: string): Promise<SearchResult[]> {
  try {
    const response = await fetch(`/api/search?term=${encodeURIComponent(searchTerm)}&type=${searchType}`);
    
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.error?.message || 'Search failed');
    }

    const results = await response.json();
    console.log('Search results:', results);
    return results.results;
  } catch (error) {
    console.error('Search error:', error);
    throw error;
  }
}


