// @ts-nocheck
import React from 'react';
import { LoginForm } from './LoginForm';

export const AuthPage = () => {
  return (
    <div className="min-h-screen bg-charcoal-950 flex flex-col items-center justify-center p-4 relative overflow-hidden">
      {/* Glowing effect */}
      <div className="absolute top-0 right-0 w-[500px] h-[500px] bg-electric-blue/20 rounded-full filter blur-[100px] -translate-y-1/2 translate-x-1/2" />
      
      <div className="w-full max-w-md relative z-10">
        <LoginForm />
      </div>
    </div>
  );
};