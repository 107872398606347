// @ts-nocheck
import { LearningPathResponse, LearningPath } from './types';

export function validateLearningPath(data: any): LearningPathResponse {
  if (!data || !Array.isArray(data.learningPaths)) {
    throw new Error('Invalid learning path response format');
  }

  const firstPath = data.learningPaths[0];
  if (!firstPath) {
    throw new Error('No learning paths available');
  }

  return {
    name: firstPath.name,
    description: firstPath.description,
    sections: firstPath.sections,
    learningPaths: [validateSinglePath(firstPath)]
  };
}

function validateSinglePath(path: any): LearningPath {
  if (!path.name || typeof path.name !== 'string') {
    throw new Error('Learning path must have a valid name');
  }

  if (!path.description || typeof path.description !== 'string') {
    throw new Error('Learning path must have a valid description');
  }

  if (!Array.isArray(path.sections)) {
    throw new Error('Learning path must have sections array');
  }

  return {
    title: path.title,
    name: path.name,
    description: path.description,
    sections: path.sections.map(validateSection)
  };
}

function validateSection(section: any) {
  if (!section.title || typeof section.title !== 'string') {
    throw new Error('Section must have a valid title');
  }

  if (!section.description || typeof section.description !== 'string') {
    throw new Error('Section must have a valid description');
  }

  if (!Array.isArray(section.items)) {
    throw new Error('Section must have items array');
  }

  return {
    title: section.title,
    description: section.description,
    items: section.items.map(validateItem)
  };
}

function validateItem(item: any) {
  if (!item.searchTerm || typeof item.searchTerm !== 'string') {
    throw new Error('Item must have a valid searchTerm');
  }

  if (!['course', 'assessment', 'lab'].includes(item.searchType)) {
    throw new Error('Item must have a valid searchType');
  }

  return {
    searchTerm: item.searchTerm,
    searchType: item.searchType
  };
}