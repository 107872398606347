// @ts-nocheck
import { AIMessage, AIModel } from './types';
import { getAIResponse } from '../providers';
import { LEARNING_PATH_PROMPT } from '../learningPath/prompts';
import { validateLearningPath } from './validator';
import { LearningPathResponse } from './types';

export async function generateLearningPath(
  requirements: string[],
  model: AIModel,
): Promise<LearningPathResponse> {
  try {
    const messages: AIMessage[] = [
      {
        id: 'system-context',
        role: 'system',
        content: LEARNING_PATH_PROMPT,
        timestamp: new Date(),
        model
      },
      {
        id: 'requirements-context',
        role: 'system',
        content: 'Here is what the user needs. This will be in an array where each item will be their response to questions about their learning needs. Your task is to use whatever is provided to create the best path/s possible.',
        timestamp: new Date(),
        model
      },
      {
        id: 'user-requirements',
        role: 'user',
        content: JSON.stringify(requirements),
        timestamp: new Date(),
        model
      }
    ];
    console.log(messages)
    const response = await getAIResponse(messages, model);
    console.log('Generated learning path:', response.content);
    const parsedResponse = JSON.parse(response.content);
    const validatedResponse = validateLearningPath(parsedResponse);
    return validatedResponse; // Ensure the validated response is returned
  } catch (error) {
    console.error('Failed to generate learning path:', error);
    throw error;
  }
}