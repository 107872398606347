// @ts-nocheck
export const STRIPE_PLANS = {
  basic: {
    id: 'price_basic',
    name: 'Basic',
    price: 9.99,
    features: [
      'Basic features',
      'Email support',
      '1 project',
      '5GB storage'
    ]
  },
  pro: {
    id: 'price_pro',
    name: 'Pro',
    price: 19.99,
    features: [
      'All Basic features',
      'Priority support',
      '5 projects',
      '20GB storage',
      'Advanced analytics'
    ],
    popular: true
  },
  enterprise: {
    id: 'price_enterprise',
    name: 'Enterprise',
    price: 49.99,
    features: [
      'All Pro features',
      '24/7 support',
      'Unlimited projects',
      'Unlimited storage',
      'Custom integrations',
      'Team collaboration'
    ]
  }
} as const;

export type StripePlan = keyof typeof STRIPE_PLANS;