// @ts-nocheck
import React, { useEffect, useState } from 'react';
import { useAuthStore } from '@/lib/store';
import { UserAvatar } from '../layout/UserAvatar';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '@/lib/firebase';
import { cn } from '@/lib/utils';

interface ProfileForm {
  firstName: string;
  lastName: string;
  organization: string;
  role: string;
  learningInstructions: string;
}

export function ProfileSettings() {
  const user = useAuthStore((state) => state.user);
  const profile = useAuthStore((state) => state.profile);
  const setProfile = useAuthStore((state) => state.setProfile);
  const [error, setError] = useState<string | null>(null);
  const [saving, setSaving] = useState(false);
  
  const [form, setForm] = useState<ProfileForm>({
    firstName: '',
    lastName: '',
    organization: '',
    role: '',
    learningInstructions: '',
  });

  // Update form when profile changes
  useEffect(() => {
    if (profile) {
      setForm({
        firstName: profile.firstName || '',
        lastName: profile.lastName || '',
        organization: profile.organization || '',
        role: profile.role || '',
        learningInstructions: profile.learningInstructions || '',
      });
    }
  }, [profile]);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target;
    setForm(prev => ({ ...prev, [name]: value }));
  };

  const handleBlur = async (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    if (!user) return;
    
    const { name, value } = e.target;
    setSaving(true);
    setError(null);

    try {
      const userRef = doc(db, 'users', user.uid);
      await updateDoc(userRef, {
        [`profile.${name}`]: value || null,
        updatedAt: new Date()
      });

      // Update local state
      setProfile({
        ...profile,
        [name]: value || null
      });
    } catch (err) {
      console.error('Error updating profile:', err);
      setError('Failed to save changes. Please try again.');
    } finally {
      setSaving(false);
    }
  };

  if (!user) return null;

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-4">
        <UserAvatar email={user.email!} className="w-16 h-16 text-xl" />
        <div>
          <h3 className="text-lg font-medium text-white">Profile</h3>
          <p className="text-sm text-gray-400">{user.email}</p>
        </div>
      </div>

      {error && (
        <div className="p-3 bg-red-500/10 border border-red-500/20 rounded-lg text-red-400">
          {error}
        </div>
      )}

      {saving && (
        <div className="p-3 bg-electric-blue/10 border border-electric-blue/20 rounded-lg text-electric-blue">
          Saving changes...
        </div>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-300">First Name</label>
          <input
            type="text"
            name="firstName"
            value={form.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            className={cn(
              "w-full px-3 py-2",
              "bg-gray-700 border border-gray-600 rounded-lg",
              "focus:ring-2 focus:ring-electric-blue focus:border-transparent",
              "text-white"
            )}
          />
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-300">Last Name</label>
          <input
            type="text"
            name="lastName"
            value={form.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            className={cn(
              "w-full px-3 py-2",
              "bg-gray-700 border border-gray-600 rounded-lg",
              "focus:ring-2 focus:ring-electric-blue focus:border-transparent",
              "text-white"
            )}
          />
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-300">Organization</label>
          <input
            type="text"
            name="organization"
            value={form.organization}
            onChange={handleChange}
            onBlur={handleBlur}
            className={cn(
              "w-full px-3 py-2",
              "bg-gray-700 border border-gray-600 rounded-lg",
              "focus:ring-2 focus:ring-electric-blue focus:border-transparent",
              "text-white"
            )}
          />
        </div>

        <div className="space-y-2">
          <label className="text-sm font-medium text-gray-300">Role</label>
          <input
            type="text"
            name="role"
            value={form.role}
            onChange={handleChange}
            onBlur={handleBlur}
            className={cn(
              "w-full px-3 py-2",
              "bg-gray-700 border border-gray-600 rounded-lg",
              "focus:ring-2 focus:ring-electric-blue focus:border-transparent",
              "text-white"
            )}
          />
        </div>
      </div>

      <div className="space-y-2">
        <label className="text-sm font-medium text-gray-300">Learning Instructions</label>
        <textarea
          name="learningInstructions"
          value={form.learningInstructions}
          onChange={handleChange}
          onBlur={handleBlur}
          rows={4}
          className={cn(
            "w-full px-3 py-2",
            "bg-gray-700 border border-gray-600 rounded-lg",
            "focus:ring-2 focus:ring-electric-blue focus:border-transparent",
            "text-white resize-none"
          )}
          placeholder="Enter any specific learning preferences or instructions..."
        />
      </div>
    </div>
  );
}