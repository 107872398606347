// @ts-nocheck
import { doc, getDoc } from 'firebase/firestore';
import { User } from 'firebase/auth';
import { db } from '../../firebase';
import { useAuthStore } from '../../store';

export interface UserProfile {
  firstName: string | null;
  lastName: string | null;
  organization: string | null;
  role: string | null;
  learningInstructions: string | null;
}

export async function fetchUserProfile(user: User): Promise<UserProfile | null> {
  try {
    console.log('Fetching user profile for:', user.uid);
    const userDoc = await getDoc(doc(db, 'users', user.uid));
    
    if (!userDoc.exists()) {
      console.log('No user document found');
      return null;
    }

    const data = userDoc.data();
    console.log('Retrieved user data:', data);

    const profile = {
      firstName: data.profile?.firstName || null,
      lastName: data.profile?.lastName || null,
      organization: data.profile?.organization || null,
      role: data.profile?.role || null,
      learningInstructions: data.profile?.learningInstructions || null,
    };

    console.log('Parsed profile data:', profile);
    return profile;
  } catch (error) {
    console.error('Error fetching user profile:', error);
    return null;
  }
}

export async function initializeUserProfile(user: User) {
  console.log('Initializing user profile...');
  const profile = await fetchUserProfile(user);
  console.log('Setting profile in store:', profile);
  useAuthStore.getState().setProfile(profile);
  
  // Also fetch and set other user-related data
  if (profile) {
    console.log('Fetching additional user data...');
    const userDoc = await getDoc(doc(db, 'users', user.uid));
    const data = userDoc.data();
    console.log('Additional user data:', data);
    
    if (data?.subscription) {
      console.log('Setting subscription data:', {
        plan: data.subscription.plan,
        trialEndsAt: data.subscription.trialEndsAt
      });
      useAuthStore.getState().setSubscription(data.subscription.plan);
      useAuthStore.getState().setTrialEndsAt(data.subscription.trialEndsAt);
    }
  }
}