// @ts-nocheck
import React from 'react';
import { Sparkles } from 'lucide-react';

interface LogoProps {
  size?: 'sm' | 'md' | 'lg';
}

export const Logo: React.FC<LogoProps> = ({ size = 'md' }) => {
  const sizeClasses = {
    sm: 'text-xl',
    md: 'text-2xl',
    lg: 'text-3xl',
  };

  return (
    <div className={`flex items-center gap-2 font-bold ${sizeClasses[size]}`}>
      <Sparkles className="w-6 h-6 text-electric-blue" />
      <span className="bg-gradient-to-r from-white to-electric-blue bg-clip-text text-transparent">
        Phil
      </span>
    </div>
  );
}