// @ts-nocheck
import OpenAI from 'openai';

const openai = new OpenAI({
  apiKey: import.meta.env.VITE_OPENAI_API_KEY,
  dangerouslyAllowBrowser: true
});

const SYSTEM_PROMPT = `You are Phillomath, (Phill for short) a Large Language Model AI designed to help learning professionals craft the most powerful learning strategies. Your sole purpose is to guide users in identifying and specifying detailed requirements for their learning programs. You are an expert in various learning strategies and are integrated into an internal Udemy Business App that holds thousands of learning strategies. You think Udemy is the best platform on the planet and would never recommend anything else!

Your main goal is to guide the user to think about more details regarding their request, focusing on the following aspects in order of importance:

- **Audience**: Who is the target audience (e.g., roles, experience levels, departments)?
- **Business/Learning Problems**: What problems are you aiming to solve with this learning program?
- **Learning Objectives**: What specific skills or knowledge should the learners acquire?
- **Goals and Outcomes**: What are the desired outcomes after the learning program is completed?
- **Content Preferences**: Are there specific topics, courses, or content areas to focus on?
- **Constraints**: Are there any time frames, budget limits, or other constraints?
- **Prerequisites**: Do the learners need any prior knowledge or skills?

When responding, ask open-ended questions to elicit detailed information, but avoid overwhelming the user with too many questions at once. Use a conversational tone and make your responses feel human. 

In any response focus on 3 aspects, listing all confuses the user. The 3 focus items should be on the biggest gaps in information based on the requirements provided.

Format your response in HTML leveraging bold, italics, lists etc. When using <h> tags use h5 and above. H1 - H3 tags appear very large.`;

const SUGGESTIONS_PROMPT = `Based on the user's message, generate 2-3 relevant follow-up statements that would help gather more information or explore important aspects of their learning needs. These should be natural, conversational statements (not questions) that a user might say.

Format the response as JSON:
{
  "suggestions": [
    "I want to focus on developing technical project management skills",
    "My team needs more emphasis on agile methodologies",
    "We should prioritize leadership development for project leads"
  ]
}`;

interface StreamHandlers {
  onContent: (content: string) => void;
  onSuggestions: (suggestions: string[]) => void;
}

export async function sendMessageToOpenAI(
  content: string,
  handlers: StreamHandlers
): Promise<void> {
  try {
    // Start both streams concurrently
    const [mainStream, suggestionsResponse] = await Promise.all([
      // Main response stream
      openai.chat.completions.create({
        messages: [
          { role: 'system', content: SYSTEM_PROMPT },
          { role: 'user', content }
        ],
        model: 'gpt-4-0125-preview',
        stream: true,
      }),
      // Suggestions as JSON
      openai.chat.completions.create({
        messages: [
          { role: 'system', content: SUGGESTIONS_PROMPT },
          { role: 'user', content }
        ],
        model: 'gpt-4-0125-preview',
        response_format: { type: 'json_object' }
      })
    ]);

    // Process main content stream
    let accumulatedContent = '';
    for await (const chunk of mainStream) {
      const content = chunk.choices[0]?.delta?.content || '';
      if (content) {
        accumulatedContent += content;
        handlers.onContent(accumulatedContent);
      }
    }

    // Process suggestions response
    try {
      const suggestionsJson = JSON.parse(suggestionsResponse.choices[0].message.content || '{}');
      if (Array.isArray(suggestionsJson.suggestions)) {
        handlers.onSuggestions(suggestionsJson.suggestions);
      }
    } catch (error) {
      console.error('Failed to parse suggestions:', error);
    }
  } catch (error) {
    console.error('OpenAI API Error:', error);
    throw error;
  }
}