// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { cn } from '@/lib/utils';
import { ChevronRight, Star, GitBranch, GitMerge, Loader2, MessageSquare, ChevronDown } from 'lucide-react';

interface Skill {
  skill: string;
  subskills: string[];
  level?: string;
  dependencies?: string[];
}

interface SkillTreeProps {
  tree?: {
    Skills: Skill[];
  };
  isLoading?: boolean;
  collapsed?: boolean;
}

const LEVEL_COLORS = {
  beginner: 'from-green-500/20 to-green-600/20 border-green-500/30 text-green-400',
  intermediate: 'from-blue-500/20 to-blue-600/20 border-blue-500/30 text-blue-400',
  advanced: 'from-purple-500/20 to-purple-600/20 border-purple-500/30 text-purple-400'
};

const LEVEL_ICONS = {
  beginner: <Star className="w-4 h-4" />,
  intermediate: <GitBranch className="w-4 h-4" />,
  advanced: <GitMerge className="w-4 h-4" />
};

export function SkillTree({ tree, isLoading, collapsed = false }: SkillTreeProps = {}) {
  const [visibleSkills, setVisibleSkills] = useState<Skill[]>([]);
  const [loadingSkill, setLoadingSkill] = useState(false);
  const [expandedSkills, setExpandedSkills] = useState<string[]>([]);

  // Reset visible skills when tree changes or is cleared
  useEffect(() => {
    if (!tree) {
      setVisibleSkills([]);
      setExpandedSkills([]);
      return;
    }

    if (tree.Skills) {
      // Reset if new tree
      if (JSON.stringify(visibleSkills) !== JSON.stringify(tree.Skills)) {
        setVisibleSkills([]);
        setExpandedSkills([]);
        animateSkills(tree.Skills);
      }
    }
  }, [tree]);

  // Animate skills appearing one by one
  const animateSkills = async (skills: Skill[]) => {
    for (let i = 0; i < skills.length; i++) {
      setLoadingSkill(true);
      await new Promise(resolve => setTimeout(resolve, 300));
      setLoadingSkill(false);
      
      setVisibleSkills(prev => [...prev, skills[i]]);
      await new Promise(resolve => setTimeout(resolve, 200));
    }
  };

  const toggleSkill = (skill: string) => {
    setExpandedSkills(prev => 
      prev.includes(skill)
        ? prev.filter(s => s !== skill)
        : [...prev, skill]
    );
  };

  if (isLoading) {
    return (
      <div className="space-y-4">
        {[1, 2, 3].map((i) => (
          <div 
            key={i}
            className={cn(
              "p-4 rounded-lg",
              "bg-charcoal-800/30 border border-charcoal-700/30",
              "animate-pulse"
            )}
          >
            <div className="flex items-center gap-3 mb-4">
              <div className="w-8 h-8 rounded-lg bg-charcoal-700/50" />
              <div className="h-4 w-48 bg-charcoal-700/50 rounded" />
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (!tree || !tree.Skills || tree.Skills.length === 0) {
    return (
      <div className="text-center p-6 bg-charcoal-800/30 rounded-lg border border-charcoal-700/30">
        <div className="flex flex-col items-center gap-3">
          <MessageSquare className="w-8 h-8 text-charcoal-600" />
          <div>
            <p className="text-sm text-charcoal-400">
              Start chatting with Phil to generate your skill tree
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-3">
      {visibleSkills.map((skillGroup, index) => (
        <div 
          key={index}
          className={cn(
            "rounded-lg overflow-hidden",
            "bg-charcoal-800/30 border border-charcoal-700/30",
            "hover:border-electric-blue/20",
            "transition-all duration-200 ease-out",
            "animate-fade-in"
          )}
          style={{ animationDelay: `${index * 100}ms` }}
        >
          {/* Skill Header */}
          <button
            onClick={() => toggleSkill(skillGroup.skill)}
            className="w-full flex items-center justify-between p-3 hover:bg-charcoal-700/20"
          >
            <div className="flex items-center gap-2">
              <div className={cn(
                "p-1.5 rounded-lg",
                "bg-gradient-to-r",
                skillGroup.level ? LEVEL_COLORS[skillGroup.level as keyof typeof LEVEL_COLORS] : "from-electric-blue/20 to-blue-500/20 border-electric-blue/30 text-electric-blue"
              )}>
                {skillGroup.level ? 
                  LEVEL_ICONS[skillGroup.level as keyof typeof LEVEL_ICONS] : 
                  <ChevronRight className="w-4 h-4" />
                }
              </div>
              <h4 className="text-sm font-medium text-white">
                {skillGroup.skill}
              </h4>
            </div>
            
            <ChevronDown 
              className={cn(
                "w-4 h-4 text-charcoal-400 transition-transform duration-200",
                expandedSkills.includes(skillGroup.skill) ? "transform rotate-180" : ""
              )}
            />
          </button>

          {/* Subskills */}
          {expandedSkills.includes(skillGroup.skill) && (
            <div className="px-3 pb-3">
              <div className="space-y-2 ml-7 pt-2">
                {skillGroup.subskills.map((subskill, subIndex) => (
                  <div 
                    key={subIndex}
                    className={cn(
                      "flex items-center gap-2 p-2 rounded-lg",
                      "bg-charcoal-900/30 border border-charcoal-800/30",
                      "hover:border-electric-blue/20 hover:bg-charcoal-900/50",
                      "transition-all duration-200",
                      "animate-fade-in text-sm"
                    )}
                    style={{ animationDelay: `${(index * 100) + (subIndex * 50)}ms` }}
                  >
                    <div className="w-1.5 h-1.5 rounded-full bg-electric-blue/50" />
                    <span className="text-charcoal-300">{subskill}</span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ))}

      {/* Loading next skill indicator */}
      {loadingSkill && (
        <div className={cn(
          "p-4 rounded-lg",
          "bg-charcoal-800/30 border border-charcoal-700/30",
          "animate-pulse"
        )}>
          <div className="flex items-center gap-3">
            <Loader2 className="w-4 h-4 text-electric-blue animate-spin" />
            <div className="h-3 w-32 bg-charcoal-700/50 rounded" />
          </div>
        </div>
      )}
    </div>
  );
}