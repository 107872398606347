// @ts-nocheck
import React from 'react';
import { cn } from '@/lib/utils';

export type CanvasType = 'learning-program' | 'content-map';

interface CanvasTypeSelectorProps {
  value: CanvasType;
  onChange: (type: CanvasType) => void;
}

export function CanvasTypeSelector({ value, onChange }: CanvasTypeSelectorProps) {
  return (
    <select
      value={value}
      onChange={(e) => onChange(e.target.value as CanvasType)}
      className={cn(
        "flex-1 px-3 py-1.5 rounded-lg text-sm",
        "bg-charcoal-800/50 border border-charcoal-700/50",
        "text-charcoal-300 focus:text-white",
        "focus:outline-none focus:ring-1 focus:ring-electric-blue/50",
        "transition-colors duration-200"
      )}
    >
      <option value="learning-program">Learning Program</option>
      <option value="content-map">Content Map</option>
    </select>
  );
}