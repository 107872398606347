// @ts-nocheck
import { GoogleGenerativeAI } from '@google/generative-ai';
import { AIMessage, AIResponse } from '../types';

const genAI = new GoogleGenerativeAI(import.meta.env.VITE_GEMINI_API_KEY);

export async function chatWithGemini(messages: AIMessage[]): Promise<AIResponse> {
  try {
    const model = genAI.getGenerativeModel({ model: 'gemini-pro' });
    const chat = model.startChat();

    // Process messages sequentially to maintain context
    for (const msg of messages) {
      if (msg.role === 'user' || msg.role === 'system') {
        await chat.sendMessage(msg.content);
      }
    }

    // Send the last message and get response
    const lastMessage = messages[messages.length - 1];
    const result = await chat.sendMessage(lastMessage.content);
    const response = await result.response;

    return {
      content: response.text(),
      model: 'gemini-pro',
      suggestions: []
    };
  } catch (error) {
    console.error('Gemini API error:', error);
    throw error;
  }
}