// @ts-nocheck
import React, { useEffect } from 'react';
import { cn } from '@/lib/utils';
import { SettingsPage } from './SettingsPage';

interface SettingsModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export function SettingsModal({ isOpen, onClose }: SettingsModalProps) {
  // Prevent scroll when modal is open
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div 
      className={cn(
        "fixed inset-0 bg-charcoal-950/80 backdrop-blur-sm",
        "z-[9999]",
        "animate-in fade-in duration-200"
      )}
    >
      <div className="h-full overflow-auto">
        <div className="relative w-full max-w-6xl mx-auto py-6 px-4 sm:px-6">
          <button
            onClick={onClose}
            className={cn(
              "absolute top-8 right-8 p-2",
              "text-charcoal-400 hover:text-white",
              "rounded-lg hover:bg-charcoal-800/50",
              "transition-colors"
            )}
          >
            <span className="sr-only">Close</span>
            <svg className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          
          <div className={cn(
            "bg-charcoal-950 rounded-xl",
            "shadow-2xl shadow-charcoal-950/50",
            "border border-charcoal-800/50",
            "animate-in slide-in-from-bottom-4 duration-300"
          )}>
            <SettingsPage />
          </div>
        </div>
      </div>
    </div>
  );
}