// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { SkillTree } from './SkillTree';
import { LearningPath } from './LearningPath';
import { useChatListStore } from '@/lib/stores/chatListStore';
import { generateLearningPath } from '@/lib/services/ai/learningPath/generator';
import { cn } from '@/lib/utils';
import { Sparkles, Loader2 } from 'lucide-react';

export function Canvas() {
  const { activeChat, chats, updateChat } = useChatListStore();
  const [isGeneratingSkills, setIsGeneratingSkills] = useState(false);
  const [isGeneratingPath, setIsGeneratingPath] = useState(false);
  const activeSkillTree = activeChat ? chats.find(c => c.id === activeChat)?.skillTree : null;
  const activeLearningPath = activeChat ? chats.find(c => c.id === activeChat)?.learningPath : null;

  // Reset loading state when active chat changes
  useEffect(() => {
    if (activeChat) {
      setIsGeneratingSkills(true);
    }
  }, [activeChat]);

  // Update loading state when skill tree changes
  useEffect(() => {
    if (activeSkillTree) {
      setIsGeneratingSkills(false);
    }
  }, [activeSkillTree]);

  const handleGeneratePath = async () => {
    if (!activeChat) return;
    
    setIsGeneratingPath(true);
    try {
      const path = await generateLearningPath(
        chats.find(c => c.id === activeChat)?.messages.map(message => message.content) || [],
        'gpt-4-0125-preview'
      );
      
      updateChat(activeChat, chat => ({
        ...chat,
        learningPath: {
          ...path,
          name: path.name || '',
          description: path.description || '',
          sections: path.sections || []
        }
      }));
    } catch (error) {
      console.error('Failed to generate learning path:', error);
    } finally {
      setIsGeneratingPath(false);
    }
  };

  return (
    <div className="h-full bg-charcoal-900/50 overflow-hidden">
      <div className="h-full flex">
        {/* Main Learning Path Column */}
        <div className="flex-1 p-6 overflow-y-auto">
          <div className="max-w-3xl mx-auto space-y-8">
            {/* Generate Path Button */}
            <button
              onClick={handleGeneratePath}
              disabled={isGeneratingPath || !activeChat}
              className={cn(
                "group relative w-full",
                "px-6 py-4 rounded-lg",
                "bg-gradient-to-r from-electric-blue/10 to-blue-500/10",
                "border border-electric-blue/20 hover:border-electric-blue/40",
                "transition-all duration-300 ease-out",
                "overflow-hidden",
                "disabled:opacity-50 disabled:cursor-not-allowed"
              )}
            >
              {/* Animated background */}
              <div className={cn(
                "absolute inset-0 opacity-0 group-hover:opacity-100",
                "bg-gradient-to-r from-electric-blue/5 to-blue-500/5",
                "transition-opacity duration-300"
              )} />

              {/* Button content */}
              <div className="relative flex items-center justify-center gap-3">
                {isGeneratingPath ? (
                  <Loader2 className="w-5 h-5 text-electric-blue animate-spin" />
                ) : (
                  <Sparkles className="w-5 h-5 text-electric-blue" />
                )}
                <span className="text-electric-blue font-medium">
                  {isGeneratingPath ? "Generating Learning Path..." : "Generate Learning Path"}
                </span>
              </div>
            </button>

            {/* Learning Path Section */}
            <div className="space-y-4">
              <h2 className="text-xl font-semibold text-white">Learning Path</h2>
              <LearningPath path={activeLearningPath} />
            </div>
          </div>
        </div>

        {/* Skill Tree Sidebar */}
        <div className="w-80 border-l border-charcoal-800 p-4 overflow-y-auto">
          <div className="space-y-4">
            <h2 className="text-lg font-semibold text-white">Required Skills</h2>
            <SkillTree 
              tree={activeSkillTree} 
              isLoading={isGeneratingSkills}
              collapsed={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}