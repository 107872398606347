// @ts-nocheck
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { Message } from './chatStore';
import { Requirement } from './requirementsStore';
import { SkillTree, LearningPath } from '../types/ai';
import { generateId } from '../utils';

export interface Chat {
  id: string;
  title: string;
  createdAt: Date;
  updatedAt: Date;
  messages: Message[];
  requirements: Requirement[];
  skillTree?: SkillTree;
  learningPath?: LearningPath;
  isExpanded?: boolean;
}

interface ChatListState {
  chats: Chat[];
  activeChat: string | null;
  sidebarVisible: boolean;
  createChat: () => string;
  deleteChat: (id: string) => void;
  setActiveChat: (id: string | null) => void;
  updateChat: (id: string, updater: ((chat: Chat) => Chat) | Partial<Chat>) => void;
  toggleSidebar: () => void;
  setSidebarVisible: (visible: boolean) => void;
  resetCanvas: () => void;
}

export const useChatListStore = create<ChatListState>()(
  persist(
    (set, get) => ({
      chats: [],
      activeChat: null,
      sidebarVisible: false,

      createChat: () => {
        const id = generateId();
        const newChat: Chat = {
          id,
          title: 'New Conversation',
          createdAt: new Date(),
          updatedAt: new Date(),
          messages: [],
          requirements: [],
          skillTree: undefined,
          learningPath: undefined,
          isExpanded: false
        };

        set(state => ({
          chats: [...state.chats, newChat],
          activeChat: id,
          sidebarVisible: false
        }));

        return id;
      },

      deleteChat: (id: string) => {
        set(state => ({
          chats: state.chats.filter(chat => chat.id !== id),
          activeChat: state.activeChat === id ? null : state.activeChat
        }));
      },

      setActiveChat: (id: string | null) => {
        // Always clear the previous chat's canvas state
        const prevActiveChat = get().activeChat;
        if (prevActiveChat) {
          set(state => ({
            chats: state.chats.map(chat => 
              chat.id === prevActiveChat ? {
                ...chat,
                skillTree: undefined,
                learningPath: undefined
              } : chat
            )
          }));
        }

        // Set new active chat
        set({ activeChat: id });
      },

      updateChat: (id: string, updater) => {
        set(state => ({
          chats: state.chats.map(chat => {
            if (chat.id !== id) return chat;
            const updates = typeof updater === 'function' ? updater(chat) : updater;
            return { ...chat, ...updates, updatedAt: new Date() };
          })
        }));
      },

      toggleSidebar: () => {
        set(state => ({ sidebarVisible: !state.sidebarVisible }));
      },

      setSidebarVisible: (visible: boolean) => {
        set({ sidebarVisible: visible });
      },

      resetCanvas: () => {
        const activeChat = get().activeChat;
        if (activeChat) {
          set(state => ({
            chats: state.chats.map(chat => 
              chat.id === activeChat ? {
                ...chat,
                skillTree: undefined,
                learningPath: undefined
              } : chat
            )
          }));
        }
      }
    }),
    {
      name: 'chat-list-storage',
      partialize: (state) => ({
        chats: state.chats.map(chat => ({
          ...chat,
          createdAt: chat.createdAt.toISOString(),
          updatedAt: chat.updatedAt.toISOString()
        })),
        activeChat: state.activeChat
      }),
      merge: (persisted: any, current) => ({
        ...current,
        ...persisted,
        chats: (persisted.chats || []).map((chat: any) => ({
          ...chat,
          createdAt: new Date(chat.createdAt),
          updatedAt: new Date(chat.updatedAt)
        }))
      })
    }
  )
);