// @ts-nocheck
import { useEffect } from 'react';
import { useChatListStore } from '../stores/chatListStore';
import { useRequirementsStore } from '../stores/requirementsStore';
import { useCanvasStore } from '../stores/canvasStore';

export function useChatSync() {
  const { activeChat, chats } = useChatListStore();
  const { setRequirements, clearRequirements } = useRequirementsStore();
  const { setTitle, reset: resetCanvas } = useCanvasStore();

  // Sync requirements and canvas title when active chat changes
  useEffect(() => {
    if (activeChat) {
      const chat = chats.find(c => c.id === activeChat);
      if (chat) {
        // Ensure requirements have proper Date objects
        const requirements = chat.requirements.map(req => ({
          ...req,
          timestamp: req.timestamp instanceof Date ? req.timestamp : new Date(req.timestamp)
        }));
        setRequirements(requirements);
        setTitle(chat.title);
      }
    } else {
      clearRequirements();
      resetCanvas();
    }
  }, [activeChat, chats, setRequirements, clearRequirements, setTitle, resetCanvas]);
}