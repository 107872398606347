// @ts-nocheck
import React from 'react';
import { Sun, Moon } from 'lucide-react';
import { useTheme } from '@/lib/hooks/useTheme';

export const ThemeToggle = () => {
  const { theme, toggleTheme } = useTheme();

  return (
    <button
      onClick={toggleTheme}
      className="float-left p-2 rounded-lg bg-charcoal-800 hover:bg-charcoal-700 transition-colors"
      aria-label="Toggle theme"
    >
      {theme === 'dark' ? (
        <Sun className="w-5 h-5 text-electric-blue" />
      ) : (
        <Moon className="w-5 h-5 text-electric-blue" />
      )}
    </button>
  );
};