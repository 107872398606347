// @ts-nocheck
import { useState, useCallback } from 'react';
import { FileWithPreview } from '../types/files';

interface UseFileUploadOptions {
  maxSize?: number;
  allowedTypes?: string[];
  maxFiles?: number;
  onFilesAdded?: (files: FileWithPreview[]) => void;
}

const ALLOWED_FILE_TYPES = [
  // Documents
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/plain',
  // Spreadsheets
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  'text/csv',
];

const FILE_TYPE_EXTENSIONS = {
  // Documents
  '.pdf': 'application/pdf',
  '.doc': 'application/msword',
  '.docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  '.txt': 'text/plain',
  // Spreadsheets
  '.xls': 'application/vnd.ms-excel',
  '.xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  '.csv': 'text/csv'
};

const FILE_TYPE_ICONS = {
  'application/pdf': '📄',
  'application/msword': '📝',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': '📝',
  'text/plain': '📄',
  'application/vnd.ms-excel': '📊',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': '📊',
  'text/csv': '📊',
};

export function useFileUpload({
  maxSize = 10 * 1024 * 1024, // 10MB default
  allowedTypes = ALLOWED_FILE_TYPES,
  maxFiles = 5,
  onFilesAdded
}: UseFileUploadOptions = {}) {
  const [files, setFiles] = useState<FileWithPreview[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  const getFileType = useCallback((file: File): string => {
    // First try the file's type
    if (file.type) return file.type;

    // If no type, try to determine from extension
    const extension = `.${file.name.split('.').pop()?.toLowerCase()}`;
    return FILE_TYPE_EXTENSIONS[extension as keyof typeof FILE_TYPE_EXTENSIONS] || '';
  }, []);

  const validateFile = useCallback((file: File): string | null => {
    if (file.size > maxSize) {
      return `File ${file.name} is too large. Maximum size is ${(maxSize / (1024 * 1024)).toFixed(1)}MB`;
    }

    const fileType = getFileType(file);
    if (!allowedTypes.includes(fileType)) {
      const extension = file.name.split('.').pop()?.toUpperCase() || 'unknown';
      return `${extension} files are not supported. Allowed types: PDF, DOC, DOCX, XLS, XLSX, CSV, TXT`;
    }

    return null;
  }, [maxSize, allowedTypes, getFileType]);

  const createPreview = useCallback((file: File): string => {
    const fileType = getFileType(file);
    return FILE_TYPE_ICONS[fileType as keyof typeof FILE_TYPE_ICONS] || '📄';
  }, [getFileType]);

  const addFiles = useCallback(async (newFiles: FileList | File[]) => {
    setError(null);

    if (files.length + newFiles.length > maxFiles) {
      setError(`Maximum ${maxFiles} files allowed`);
      return;
    }

    const processedFiles: FileWithPreview[] = [];
    const errors: string[] = [];

    for (const file of newFiles) {
      const validationError = validateFile(file);
      if (validationError) {
        errors.push(validationError);
        continue;
      }

      const preview = createPreview(file);
      processedFiles.push({ file, preview });
    }

    if (errors.length > 0) {
      setError(errors[0]); // Show first error
    }

    if (processedFiles.length > 0) {
      const newFileList = [...files, ...processedFiles];
      setFiles(newFileList);
      onFilesAdded?.(newFileList);
    }
  }, [files, maxFiles, validateFile, createPreview, onFilesAdded]);

  const removeFile = useCallback((index: number) => {
    setFiles(prev => {
      const newFiles = [...prev];
      newFiles.splice(index, 1);
      onFilesAdded?.(newFiles);
      return newFiles;
    });
    setError(null);
  }, [onFilesAdded]);

  const clearFiles = useCallback(() => {
    setFiles([]);
    onFilesAdded?.([]);
    setError(null);
  }, [onFilesAdded]);

  return {
    files,
    error,
    isDragging,
    setIsDragging,
    addFiles,
    removeFile,
    clearFiles
  };
}