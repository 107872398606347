// @ts-nocheck
import React, { useRef, useCallback } from 'react';
import { Send, Mic, Paperclip } from 'lucide-react';
import { useChat } from '@/lib/hooks/useChat';
import { useVoiceInput } from '@/lib/hooks/useVoiceInput';
import { FilePreview } from './FilePreview';
import { cn } from '@/lib/utils';
import { FileWithPreview } from '@/lib/types/files';

interface ChatInputProps {
  files: FileWithPreview[];
  onRemoveFile: (index: number) => void;
  onClearFiles: () => void;
  onAddFiles: (files: FileList | File[]) => void;
  onSend?: (message: string) => void;
}

export function ChatInput({ files, onRemoveFile, onClearFiles, onAddFiles, onSend }: ChatInputProps) {
  const { inputValue, setInputValue, sendMessage } = useChat();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  const { isListening, startListening, stopListening, isSupported } = useVoiceInput({
    onResult: (transcript) => {
      setInputValue(transcript);
      adjustTextareaHeight();
    }
  });

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!inputValue.trim() && files.length === 0) return;

    const message = inputValue.trim();
    
    if (onSend) {
      // If we have an onSend callback, use it
      await onSend(message);
    } else {
      // Otherwise send message directly through chat hook
      await sendMessage(message, files.map(f => f.file));
    }
    
    setInputValue('');
    onClearFiles();
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const handleFileSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      onAddFiles(e.target.files);
    }
  };

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = 'auto';
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  return (
    <div className="p-4 pb-[1rem] bg-charcoal-950">
      <form onSubmit={handleSubmit} className="relative">
        {/* File Preview */}
        <FilePreview files={files} onRemove={onRemoveFile} />

        <div className="relative">
          <textarea
            ref={textareaRef}
            value={inputValue}
            onChange={(e) => {
              setInputValue(e.target.value);
              adjustTextareaHeight();
            }}
            onKeyDown={handleKeyPress}
            placeholder="Message Phil..."
            className={cn(
              "w-full px-4 py-3 pr-36 ml-2.5 mb-3.5",
              "bg-charcoal-800",
              "rounded-lg",
              "text-white placeholder-charcoal-400",
              "focus:outline-none",
              "resize-none overflow-y-auto",
              "scrollbar-thin scrollbar-thumb-charcoal-700 scrollbar-track-transparent",
              "max-h-[200px]",
              "border-2 border-electric-blue/50",
              "transition-all duration-200"
            )}
            rows={1}
          />

          <div className="absolute right-2 top-[20%] flex items-center gap-2">
            <input
              ref={fileInputRef}
              type="file"
              multiple
              onChange={handleFileSelect}
              className="hidden"
              accept=".pdf,.doc,.docx,.xls,.xlsx,.csv,.txt,image/*"
            />
            
            <button
              type="button"
              onClick={() => fileInputRef.current?.click()}
              className="p-1 text-charcoal-400 hover:text-white transition-colors"
            >
              <Paperclip className="w-5 h-5" />
            </button>

            {isSupported && (
              <button
                type="button"
                onClick={isListening ? stopListening : startListening}
                className={cn(
                  "p-1 rounded-full transition-all duration-200",
                  isListening ? "text-electric-blue animate-pulse" : "text-charcoal-400 hover:text-white"
                )}
              >
                <Mic className="w-5 h-5" />
              </button>
            )}

            <button
              type="submit"
              disabled={!inputValue.trim() && files.length === 0}
              className="p-1 text-electric-blue hover:text-electric-blue/80 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
            >
              <Send className="w-5 h-5" />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}