// @ts-nocheck
import { AIError } from './types';

export class AIServiceError extends Error implements AIError {
  constructor(
    public code: string,
    message: string,
    public originalError?: unknown
  ) {
    super(message);
    this.name = 'AIServiceError';
  }

  static fromError(error: unknown): AIServiceError {
    if (error instanceof AIServiceError) {
      return error;
    }

    // Handle network errors
    if (error instanceof Error) {
      if (error.message.includes('network') || error.message.includes('connection')) {
        return new AIServiceError(
          'network_error',
          'A network error occurred. Please check your connection.',
          error
        );
      }

      if (error.message.includes('timeout')) {
        return new AIServiceError(
          'timeout',
          'The request timed out. Please try again.',
          error
        );
      }

      if (error.message.includes('rate limit')) {
        return new AIServiceError(
          'rate_limit',
          'Rate limit exceeded. Please wait a moment and try again.',
          error
        );
      }
    }

    // Handle API-specific errors
    if (error && typeof error === 'object' && 'code' in error) {
      const apiError = error as { code: string; message?: string };
      return new AIServiceError(
        apiError.code,
        apiError.message || 'An API error occurred',
        error
      );
    }

    return new AIServiceError(
      'unknown',
      'An unexpected error occurred. Please try again.',
      error
    );
  }

  get userMessage(): string {
    switch (this.code) {
      case 'network_error':
        return 'Unable to connect. Please check your internet connection.';
      case 'timeout':
        return 'The request took too long. Please try again.';
      case 'rate_limit':
        return 'Too many requests. Please wait a moment before trying again.';
      case 'invalid_response':
        return 'Received an invalid response. Please try again.';
      default:
        return 'An error occurred. Please try again later.';
    }
  }
}

export function handleAIError(error: unknown): AIServiceError {
  const aiError = AIServiceError.fromError(error);
  console.error(`AI Service Error (${aiError.code}):`, {
    message: aiError.message,
    originalError: aiError.originalError
  });
  return aiError;
}

export function isAIServiceError(error: unknown): error is AIServiceError {
  return error instanceof AIServiceError;
}

export function getErrorMessage(error: unknown): string {
  if (error instanceof AIServiceError) {
    return error.userMessage;
  }
  if (error instanceof Error) {
    return error.message;
  }
  return 'An unexpected error occurred';
}