// @ts-nocheck
import React, { useState, useRef, useEffect } from 'react';
import { Settings, LogOut } from 'lucide-react';
import { useAuthStore } from '@/lib/store';
import { signOut } from '@/lib/auth';
import { cn } from '@/lib/utils';
import { UserAvatar } from './UserAvatar';

interface UserMenuProps {
  onShowSettings: () => void;
}

export function UserMenu({ onShowSettings }: UserMenuProps) {
  const [isOpen, setIsOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const user = useAuthStore((state) => state.user);
  const profile = useAuthStore((state) => state.profile);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  if (!user?.email) return null;

  // Get display name from profile or email
  const displayName = profile?.firstName && profile?.lastName
    ? `${profile.firstName} ${profile.lastName}`
    : user.email;

  // Get role/organization info
  const subtitle = profile?.role && profile?.organization
    ? `${profile.role} at ${profile.organization}`
    : profile?.role || profile?.organization || user.email;

  return (
    <div className="relative" ref={menuRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="focus:outline-none"
        aria-label="User menu"
      >
        <UserAvatar />
      </button>

      {isOpen && (
        <div className={cn(
          "absolute right-0 mt-2 w-64",
          "bg-charcoal-800/95 backdrop-blur-sm rounded-lg",
          "shadow-xl shadow-charcoal-950/50",
          "border border-charcoal-700/50",
          "py-1",
          "z-[100]",
          "animate-in fade-in slide-in-from-top-2 duration-200"
        )}>
          <div className="px-4 py-3 border-b border-charcoal-700/50">
            <p className="text-sm font-medium text-white truncate">
              {displayName}
            </p>
            <p className="text-sm text-charcoal-400 truncate">
              {subtitle}
            </p>
          </div>

          <button
            onClick={() => {
              onShowSettings();
              setIsOpen(false);
            }}
            className={cn(
              "flex items-center gap-2 px-4 py-2.5 w-full",
              "text-charcoal-300 hover:text-white hover:bg-charcoal-700/50",
              "cursor-pointer transition-colors duration-200"
            )}
          >
            <Settings className="w-4 h-4" />
            <span>Settings</span>
          </button>

          <button
            onClick={() => signOut()}
            className={cn(
              "flex items-center gap-2 px-4 py-2.5 w-full",
              "text-charcoal-300 hover:text-white hover:bg-charcoal-700/50",
              "transition-colors duration-200"
            )}
          >
            <LogOut className="w-4 h-4" />
            <span>Sign out</span>
          </button>
        </div>
      )}
    </div>
  );
}