// @ts-nocheck
import React from 'react';
import { Shield, Key } from 'lucide-react';

export const SecuritySettings = () => {
  return (
    <div className="space-y-6">
      <h3 className="text-lg font-medium text-white">Security</h3>
      
      <div className="space-y-4">
        <div className="bg-gray-800 p-4 rounded-lg">
          <div className="flex items-center gap-3">
            <Key className="w-5 h-5 text-electric-blue" />
            <div>
              <p className="text-sm font-medium text-white">Change Password</p>
              <p className="text-sm text-gray-400">Update your password</p>
            </div>
          </div>
        </div>
        
        <div className="bg-gray-800 p-4 rounded-lg">
          <div className="flex items-center gap-3">
            <Shield className="w-5 h-5 text-electric-blue" />
            <div>
              <p className="text-sm font-medium text-white">Two-Factor Authentication</p>
              <p className="text-sm text-gray-400">Add an extra layer of security</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};