// @ts-nocheck
import React from 'react';
import { Cpu } from 'lucide-react';
import { useAIModel } from '@/lib/hooks/useAIModel';
import { cn } from '@/lib/utils';

export function AISettings() {
  const { currentModel, availableModels, switchModel } = useAIModel();

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-3">
        <Cpu className="w-6 h-6 text-electric-blue" />
        <h3 className="text-lg font-medium text-white">AI Model Settings</h3>
      </div>

      <div className="space-y-4">
        {availableModels.map((model) => (
          <div
            key={model.id}
            className={cn(
              "flex items-center justify-between p-4 rounded-lg",
              "bg-charcoal-800/50 border border-charcoal-700/50",
              "transition-colors duration-200",
              currentModel === model.id && "border-electric-blue/50"
            )}
          >
            <div>
              <h4 className="text-sm font-medium text-white">{model.name}</h4>
              <p className="text-sm text-charcoal-400">{model.description}</p>
            </div>
            <button
              onClick={() => switchModel(model.id)}
              className={cn(
                "px-3 py-1.5 rounded-lg text-sm font-medium",
                "transition-colors duration-200",
                currentModel === model.id
                  ? "bg-electric-blue text-charcoal-900"
                  : "bg-charcoal-700 text-charcoal-300 hover:bg-charcoal-600"
              )}
            >
              {currentModel === model.id ? 'Active' : 'Select'}
            </button>
          </div>
        ))}
      </div>
    </div>
  );
}