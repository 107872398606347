// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { cn } from '@/lib/utils';
import { searchContent } from '@/lib/services/search';
import { Loader2, ArrowRight, ArrowLeft, Search } from 'lucide-react';
import debounce from 'lodash/debounce';

interface PathItem {
  type: 'course' | 'resource' | 'exercise';
  title: string;
  description: string;
  searchTerm: string;
  searchType: string;
}

interface PathSection {
  title: string;
  description: string;
  items: PathItem[];
}

export type LearningPath = {
  path?: LearningPathData;
  title: string;
  description: string;
  sections: PathSection[];

};

export type LearningPathResponse = {
  title: string;

  description: string;

  sections: PathSection[];
}


interface LearningPathData {
  title: string;
  description: string;
  sections: PathSection[];
}

interface LearningPathProps {
  path?: LearningPathData;

  
}

interface SearchState {
  results: any[];
  currentIndex: number;
  isLoading: boolean;
  error?: string;
  searchTerm: string;
  lastValidSearch?: string;
}

export function LearningPath({ path }: LearningPathProps = {}) {
  const [searchStates, setSearchStates] = useState<Record<string, SearchState>>({});
  const [error, setError] = useState<string | null>(null);

  // Log the learning path object
  useEffect(() => {
    console.log('Learning Path:', path);
  }, [path]);

  // Initialize search states for each item
  useEffect(() => {
    if (path?.sections) {
      const initialStates: Record<string, SearchState> = {};
      path.sections.forEach(section => {
        section.items.forEach(item => {
          const key = `${section.title}-${item.searchTerm}`;
          if (!searchStates[key]) {
            initialStates[key] = {
              results: [],
              currentIndex: 0,
              isLoading: false,
              searchTerm: item.searchTerm
            };
          }
        });
      });
      setSearchStates(prev => ({ ...prev, ...initialStates }));
    }
  }, [path?.sections]); // Add path?.sections as dependency

  // Perform initial searches
  useEffect(() => {
    const searchKeys = new Set<string>();
    if (path?.sections) {
      path.sections.forEach(section => {
        section.items.forEach(item => {
          const key = `${section.title}-${item.searchTerm}`;
          if (!searchKeys.has(key) && !searchStates[key]?.results.length) {
            searchKeys.add(key);
            handleSearch(key, item.searchTerm, item.searchType);
          }
        });
      });
    }
  }, [path?.sections]); // Add path?.sections as dependency

  const handleSearch = async (key: string, term: string, type: string) => {
    if (term.length < 2) {
      setSearchStates(prev => ({
        ...prev,
        [key]: {
          ...prev[key],
          results: [],
          currentIndex: 0,
          isLoading: false,
          error: 'Search term must be at least 2 characters'
        }
      }));
      return;
    }

    setSearchStates(prev => ({
      ...prev,
      [key]: {
        ...prev[key],
        isLoading: true,
        error: undefined,
        searchTerm: term
      }
    }));

    try {
      const results = await searchContent(term, type);
      setSearchStates(prev => ({
        ...prev,
        [key]: {
          ...prev[key],
          results,
          currentIndex: 0,
          isLoading: false
        }
      }));
      setError(null); // Clear any previous errors
    } catch (error) {
      console.error('Search error:', error);
      setSearchStates(prev => ({
        ...prev,
        [key]: {
          ...prev[key],
          results: [],
          currentIndex: 0,
          isLoading: false,
          error: 'Failed to fetch results'
        }
      }));
      setError('An error occurred while searching. Please try again.');
    }
  };

  // At the top of component, add debounced search
  const debouncedSearch = React.useCallback(
    debounce((key: string, term: string, type: string) => {
      if (term.length >= 2) {
        handleSearch(key, term, type);
      }
    }, 300),
    []
  );

  const navigateResults = (key: string, direction: 'next' | 'prev') => {
    setSearchStates(prev => {
      const state = prev[key];
      if (!state?.results.length) return prev;

      const newIndex = direction === 'next'
        ? (state.currentIndex + 1) % state.results.length
        : (state.currentIndex - 1 + state.results.length) % state.results.length;

      return {
        ...prev,
        [key]: {
          ...state,
          currentIndex: newIndex
        }
      };
    });
  };

  if (!path?.sections) {
    return (
      <div className="text-center p-6 bg-charcoal-800/30 rounded-lg border border-charcoal-700/30">
        <p className="text-charcoal-400">
          Your learning path will appear here
        </p>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <div className="bg-charcoal-800/30 rounded-lg p-6 border border-charcoal-700/30">
        <h3 className="text-xl font-semibold text-white mb-2">{path.title}</h3>
        <p className="text-charcoal-300">{path.description}</p>
      </div>

      {path.sections.map((section, sectionIndex) => (
        <div 
          key={sectionIndex}
          className={cn(
            "p-6 rounded-lg",
            "bg-charcoal-800/30 border border-charcoal-700/30",
            "animate-fade-in"
          )}
          style={{ animationDelay: `${sectionIndex * 150}ms` }}
        >
          <div className="flex items-start gap-4">
            <div className="flex-shrink-0 w-8 h-8 rounded-full bg-charcoal-700 flex items-center justify-center">
              <span className="text-sm font-medium text-white">
                {sectionIndex + 1}
              </span>
            </div>
            
            <div className="flex-1">
              <h3 className="text-xl font-semibold text-white mb-2">
                {section.title}
              </h3>
              <p className="text-charcoal-300 mb-6">
                {section.description}
              </p>

              <div className="space-y-4">
                {section.items.map((item, itemIndex) => {
                  const key = `${section.title}-${item.searchTerm}`;
                  const searchState = searchStates[key];
                  const currentResult = searchState?.results[searchState?.currentIndex];

                  return (
                    <div 
                      key={itemIndex}
                      className={cn(
                        "p-4 rounded-lg",
                        "bg-charcoal-900/50 border border-charcoal-800/50",
                        "transition-colors duration-200"
                      )}
                    >
                      {/* Search Input */}
                      <div className="flex items-center gap-4 mb-4">
                        <div className="relative flex-1">
                          <input
                            type="text"
                            value={searchState?.searchTerm || item.searchTerm}
                            onChange={(e) => {
                              const term = e.target.value;
                              // Update state
                              setSearchStates(prev => ({
                                ...prev,
                                [key]: {
                                  ...prev[key],
                                  searchTerm: term
                                }
                              }));
                              // Trigger debounced search
                              debouncedSearch(key, term, item.searchType);
                            }}
                            className={cn(
                              "w-full pl-10 pr-4 py-2 rounded-lg",
                              "bg-charcoal-800 border border-charcoal-700",
                              "text-white placeholder-charcoal-400",
                              "focus:outline-none focus:ring-2 focus:ring-electric-blue/50"
                            )}
                          />
                          <Search className="absolute left-3 top-1/2 -translate-y-1/2 w-4 h-4 text-charcoal-400" />
                        </div>
                        
                        {searchState?.results.length > 0 && (
                          <div className="flex items-center gap-2">
                            <button
                              onClick={() => navigateResults(key, 'prev')}
                              className="p-2 text-charcoal-400 hover:text-white"
                            >
                              <ArrowLeft className="w-4 h-4" />
                            </button>
                            <span className="text-sm text-charcoal-400">
                              {searchState.currentIndex + 1} / {searchState.results.length}
                            </span>
                            <button
                              onClick={() => navigateResults(key, 'next')}
                              className="p-2 text-charcoal-400 hover:text-white"
                            >
                              <ArrowRight className="w-4 h-4" />
                            </button>
                          </div>
                        )}
                      </div>

                      {/* Search Results */}
                      {searchState?.isLoading ? (
                        <div className="flex items-center justify-center p-4">
                          <Loader2 className="w-6 h-6 text-electric-blue animate-spin" />
                        </div>
                      ) : searchState?.error ? (
                        <div className="text-red-400 text-sm p-4 text-center">
                          {searchState.error}
                        </div>
                      ) : currentResult ? (
                        <a
                          href={currentResult.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className={cn(
                            "block rounded-lg overflow-hidden",
                            "border border-charcoal-700/50",
                            "hover:border-electric-blue/30",
                            "transition-all duration-200"
                          )}
                        >
                          <div className="aspect-video">
                            <img
                              src={currentResult.image_480x270}
                              alt={currentResult.title}
                              className="w-full h-full object-cover"
                            />
                          </div>
                          <div className="p-4">
                            <h4 className="font-medium text-white mb-2">
                              {currentResult.title}
                            </h4>
                            <p className="text-sm text-charcoal-300">
                              {currentResult.headline}
                            </p>
                          </div>
                        </a>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ))}
      {error && (
        <div className="error-message text-red-500">
          {error}
        </div>
      )}
    </div>
  );
}