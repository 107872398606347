// @ts-nocheck
import { 
  signInWithPopup, 
  GoogleAuthProvider, 
  FacebookAuthProvider, 
  GithubAuthProvider,
  signInWithEmailAndPassword as firebaseSignInWithEmail,
  createUserWithEmailAndPassword as firebaseCreateUser,
  signOut as firebaseSignOut,
  User
} from 'firebase/auth';
import { auth } from './firebase';
import { handleUserAuth } from './services/auth/userAuth';

export { auth };

export type { User };

// Provider instances
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();
const githubProvider = new GithubAuthProvider();

export const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, googleProvider);
    await handleUserAuth(result.user);
    return result.user;
  } catch (error: any) {
    console.error('Google sign in error:', error);
    throw new Error(getAuthErrorMessage(error.code));
  }
};

export const signInWithGithub = async () => {
  try {
    const result = await signInWithPopup(auth, githubProvider);
    await handleUserAuth(result.user);
    return result.user;
  } catch (error: any) {
    console.error('GitHub sign in error:', error);
    throw new Error(getAuthErrorMessage(error.code));
  }
};

export const signInWithFacebook = async () => {
  try {
    const result = await signInWithPopup(auth, facebookProvider);
    await handleUserAuth(result.user);
    return result.user;
  } catch (error: any) {
    console.error('Facebook sign in error:', error);
    throw new Error(getAuthErrorMessage(error.code));
  }
};

export const signInWithEmailAndPassword = async (email: string, password: string) => {
  try {
    const result = await firebaseSignInWithEmail(auth, email, password);
    await handleUserAuth(result.user);
    return result.user;
  } catch (error: any) {
    console.error('Email/Password sign in error:', error);
    throw new Error(getAuthErrorMessage(error.code));
  }
};

export const createUserWithEmailAndPassword = async (email: string, password: string) => {
  try {
    const result = await firebaseCreateUser(auth, email, password);
    await handleUserAuth(result.user);
    return result.user;
  } catch (error: any) {
    console.error('Sign up error:', error);
    throw new Error(getAuthErrorMessage(error.code));
  }
};

export const signOut = async () => {
  try {
    await firebaseSignOut(auth);
    await handleUserAuth(null);
  } catch (error: any) {
    console.error('Sign out error:', error);
    throw new Error(getAuthErrorMessage(error.code));
  }
};

const getAuthErrorMessage = (errorCode: string): string => {
  switch (errorCode) {
    case 'auth/invalid-email':
      return 'Invalid email address';
    case 'auth/user-disabled':
      return 'This account has been disabled';
    case 'auth/user-not-found':
      return 'No account found with this email';
    case 'auth/wrong-password':
      return 'Incorrect password';
    case 'auth/email-already-in-use':
      return 'An account already exists with this email';
    case 'auth/weak-password':
      return 'Password should be at least 6 characters';
    case 'auth/popup-closed-by-user':
      return 'Sign in was cancelled';
    case 'auth/cancelled-popup-request':
      return 'Only one sign in window can be open at a time';
    case 'auth/operation-not-allowed':
      return 'This sign in method is not enabled';
    case 'auth/popup-blocked':
      return 'Sign in popup was blocked by your browser';
    default:
      return 'An error occurred during authentication';
  }
};