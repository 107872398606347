// @ts-nocheck
import { useEffect } from 'react';
import { useAuthStore } from '../store';

export const useTheme = () => {
  const theme = useAuthStore((state) => state.settings?.theme || 'dark');
  const updateSettings = useAuthStore((state) => state.updateSettings);

  useEffect(() => {
    document.documentElement.classList.remove('light', 'dark');
    document.documentElement.classList.add(theme);
  }, [theme]);

  const toggleTheme = async () => {
    const newTheme = theme === 'dark' ? 'light' : 'dark';
    await updateSettings({ theme: newTheme });
  };

  return { theme, toggleTheme };
};