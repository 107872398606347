// @ts-nocheck
import React from 'react';
import { Search } from 'lucide-react';

interface SearchProvider {
  id: string;
  name: string;
  description: string;
  enabled: boolean;
}

const searchProviders: SearchProvider[] = [
  { id: 'udemy-mx', name: 'Udemy MX', description: 'Search Udemy courses and content', enabled: true },
  { id: 'lab', name: 'Labs', description: 'Search through available labs', enabled: true },
  { id: 'assessment', name: 'Assessments', description: 'Search assessments', enabled: true },
  { id: 'scholar', name: 'Google Scholar', description: 'Academic papers and research', enabled: true },
  { id: 'openalex', name: 'OpenAlex', description: 'Open access research', enabled: false },
  { id: 'pubmed', name: 'PubMed', description: 'Medical research database', enabled: false },
  { id: 'coursera', name: 'Coursera', description: 'Online courses', enabled: true },
  { id: 'wikipedia', name: 'Wikipedia', description: 'General knowledge', enabled: true },
  { id: 'google-books', name: 'Google Books', description: 'Book search', enabled: true },
  { id: 'youtube', name: 'YouTube', description: 'Educational videos', enabled: true },
];

export const SearchSettings = () => {
  const [providers, setProviders] = React.useState(searchProviders);

  const toggleProvider = (id: string) => {
    setProviders(prev =>
      prev.map(provider =>
        provider.id === id
          ? { ...provider, enabled: !provider.enabled }
          : provider
      )
    );
  };

  return (
    <div className="space-y-6">
      <div className="flex items-center gap-3">
        <Search className="w-6 h-6 text-electric-blue" />
        <h3 className="text-lg font-medium text-white">Search Settings</h3>
      </div>

      <div className="space-y-4">
        {providers.map(provider => (
          <div
            key={provider.id}
            className="flex items-center justify-between p-4 bg-gray-800 rounded-lg"
          >
            <div>
              <h4 className="text-sm font-medium text-white">{provider.name}</h4>
              <p className="text-sm text-gray-400">{provider.description}</p>
            </div>
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                checked={provider.enabled}
                onChange={() => toggleProvider(provider.id)}
                className="sr-only peer"
              />
              <div className="w-11 h-6 bg-gray-700 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-electric-blue/20 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-electric-blue"></div>
            </label>
          </div>
        ))}
      </div>
    </div>
  );
};