// @ts-nocheck
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { generateId } from '@/lib/utils';

export interface Requirement {
  id: string;
  category: 'content' | 'document';
  content: string;
  timestamp: Date;
  document?: {
    name: string;
    preview: string;
    type: string;
    metadata?: {
      url?: string;
      size?: number;
      lastModified?: number;
    };
  };
}

interface RequirementsState {
  requirements: Requirement[];
  addRequirement: (requirement: Omit<Requirement, 'id' | 'timestamp'>) => void;
  removeRequirement: (id: string) => void;
  updateRequirement: (id: string, content: string) => void;
  setRequirements: (requirements: Requirement[]) => void;
  clearRequirements: () => void;
}

export const useRequirementsStore = create<RequirementsState>()(
  persist(
    (set) => ({
      requirements: [],
      
      addRequirement: (requirement) => {
        set((state) => ({
          requirements: [...state.requirements, {
            ...requirement,
            id: generateId(),
            timestamp: new Date()
          }]
        }));
      },
      
      removeRequirement: (id) => {
        set((state) => ({
          requirements: state.requirements.filter(req => req.id !== id)
        }));
      },
      
      updateRequirement: (id, content) => {
        set((state) => ({
          requirements: state.requirements.map(req =>
            req.id === id ? { ...req, content } : req
          )
        }));
      },

      setRequirements: (requirements) => {
        set({ requirements });
      },
      
      clearRequirements: () => set({ requirements: [] })
    }),
    {
      name: 'requirements-storage',
      // Serialize dates to ISO strings for storage
      serialize: (state) => JSON.stringify({
        ...state,
        state: {
          ...state.state,
          requirements: state.state.requirements.map((req: Requirement) => ({
            ...req,
            timestamp: req.timestamp.toISOString()
          }))
        }
      }),
      // Deserialize ISO strings back to Date objects
      deserialize: (str) => {
        const parsed = JSON.parse(str);
        return {
          ...parsed,
          state: {
            ...parsed.state,
            requirements: parsed.state.requirements.map((req: any) => ({
              ...req,
              timestamp: new Date(req.timestamp)
            }))
          }
        };
      }
    }
  )
);