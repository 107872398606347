// @ts-nocheck
import { create } from 'zustand';
import { AIModel } from '../services/ai/types';
import { AI_MODELS } from '../services/ai/config';

interface AIModelState {
  currentModel: AIModel;
  setCurrentModel: (model: AIModel) => void;
}

export const useAIModelStore = create<AIModelState>((set) => ({
  currentModel: AI_MODELS[0].id,
  setCurrentModel: (model) => set({ currentModel: model }),
}));

export function useAIModel() {
  const { currentModel, setCurrentModel } = useAIModelStore();
  
  const availableModels = AI_MODELS.filter(model => {
    const apiKey = import.meta.env[`VITE_${model.provider.toUpperCase()}_API_KEY`];
    return model.enabled && apiKey;
  });

  const switchModel = (modelId: AIModel) => {
    const model = AI_MODELS.find(m => m.id === modelId);
    if (!model || !model.enabled) {
      throw new Error(`Model ${modelId} is not available`);
    }
    setCurrentModel(modelId);
  };

  return {
    currentModel,
    availableModels,
    switchModel,
  };
}