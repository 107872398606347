// @ts-nocheck
import React from 'react';
import { CreditCard } from 'lucide-react';
import { useAuthStore } from '@/lib/store';
import { format } from 'date-fns';
import { cn } from '@/lib/utils';
import { STRIPE_PLANS } from '@/lib/services/stripe';
import { createCheckoutSession, createPortalSession } from '@/lib/stripe';

export const SubscriptionSettings = () => {
  const subscription = useAuthStore((state) => state.subscription);
  const trialEndsAt = useAuthStore((state) => state.trialEndsAt);
  const [isLoading, setIsLoading] = React.useState<string | null>(null);

  const formatDate = (date: any) => {
    if (!date) return null;
    const dateObj = date instanceof Date ? date : date.toDate();
    return format(dateObj, 'MMMM d, yyyy');
  };

  const handleSubscribe = async (planId: string) => {
    try {
      setIsLoading(planId);
      await createCheckoutSession(planId);
    } catch (error) {
      console.error('Failed to create checkout session:', error);
      alert('Failed to start checkout process. Please try again.');
    } finally {
      setIsLoading(null);
    }
  };

  const handleManageSubscription = async () => {
    try {
      setIsLoading('portal');
      await createPortalSession();
    } catch (error) {
      console.error('Failed to open customer portal:', error);
      alert('Failed to open subscription management. Please try again.');
    } finally {
      setIsLoading(null);
    }
  };

  return (
    <div className="space-y-8">
      {/* Current Subscription Status */}
      <div className="space-y-6">
        <div className="flex items-center justify-between">
          <h2 className="text-2xl font-bold text-white">Current Subscription</h2>
          {subscription && (
            <span className="px-3 py-1 bg-electric-blue/10 text-electric-blue rounded-full text-sm">
              Active
            </span>
          )}
        </div>

        <div className="grid gap-6">
          {/* Trial Status */}
          {trialEndsAt && (
            <div className={cn(
              "p-6 rounded-lg",
              "bg-gradient-to-r from-electric-blue/10 to-blue-500/10",
              "border border-electric-blue/20"
            )}>
              <div className="flex items-start gap-4">
                <div className="p-3 rounded-full bg-electric-blue/10">
                  <CreditCard className="w-6 h-6 text-electric-blue" />
                </div>
                <div>
                  <h3 className="text-lg font-medium text-white mb-1">Trial Period</h3>
                  <p className="text-charcoal-300">
                    Your trial ends on <span className="text-electric-blue font-medium">{formatDate(trialEndsAt)}</span>
                  </p>
                </div>
              </div>
            </div>
          )}

          {/* Current Plan */}
          <div className="p-6 bg-charcoal-800/50 rounded-lg border border-charcoal-700/50">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg font-medium text-white">
                {subscription ? `${subscription} Plan` : 'No Active Plan'}
              </h3>
              {subscription && (
                <button
                  onClick={handleManageSubscription}
                  disabled={isLoading === 'portal'}
                  className={cn(
                    "px-4 py-2 bg-charcoal-700 hover:bg-charcoal-600 rounded-lg text-sm transition-colors",
                    "disabled:opacity-50 disabled:cursor-not-allowed"
                  )}
                >
                  {isLoading === 'portal' ? 'Loading...' : 'Manage Plan'}
                </button>
              )}
            </div>
            <p className="text-charcoal-300">
              {subscription 
                ? 'Your subscription is currently active.' 
                : 'Choose a plan below to get started.'}
            </p>
          </div>
        </div>
      </div>

      {/* Available Plans */}
      <div className="space-y-6">
        <h2 className="text-2xl font-bold text-white">Available Plans</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {Object.entries(STRIPE_PLANS).map(([planId, plan]) => (
            <div
              key={planId}
              className={cn(
                "relative p-6 rounded-xl",
                "bg-charcoal-800/50 border border-charcoal-700/50",
                "hover:border-electric-blue/50 transition-colors",
                plan.popular && "border-electric-blue/50 bg-charcoal-800/80"
              )}
            >
              {plan.popular && (
                <span className="absolute -top-3 left-1/2 -translate-x-1/2 px-3 py-1 bg-electric-blue text-charcoal-900 text-sm font-medium rounded-full">
                  Most Popular
                </span>
              )}

              <div className="mb-6">
                <h3 className="text-xl font-semibold text-white mb-2">{plan.name}</h3>
                <div className="flex items-baseline gap-1">
                  <span className="text-3xl font-bold text-white">${plan.price}</span>
                  <span className="text-charcoal-400">/month</span>
                </div>
              </div>

              <ul className="space-y-3 mb-6">
                {plan.features.map((feature) => (
                  <li key={feature} className="flex items-center gap-2 text-charcoal-300">
                    <svg className="w-5 h-5 text-electric-blue" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7" />
                    </svg>
                    {feature}
                  </li>
                ))}
              </ul>

              <button
                onClick={() => handleSubscribe(plan.id)}
                disabled={isLoading === plan.id}
                className={cn(
                  "w-full py-2 rounded-lg transition-colors",
                  "disabled:opacity-50 disabled:cursor-not-allowed",
                  plan.popular
                    ? "bg-electric-blue hover:bg-electric-blue/90 text-charcoal-900 font-medium"
                    : "bg-charcoal-700 hover:bg-charcoal-600 text-white"
                )}
              >
                {isLoading === plan.id ? 'Loading...' : 'Get Started'}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}