// @ts-nocheck
import { ProcessedFile } from './fileProcessing/types';
import { generateId } from '../utils';
import { Timestamp } from 'firebase/firestore';

export async function processUrl(url: string): Promise<ProcessedFile> {
  try {
    // Use a CORS proxy for external URLs
    const proxyUrl = `https://api.allorigins.win/get?url=${encodeURIComponent(url)}`;
    const response = await fetch(proxyUrl);
    
    if (!response.ok) {
      throw new Error(`Failed to fetch URL: ${response.statusText}`);
    }

    const data = await response.json();
    const parser = new DOMParser();
    const doc = parser.parseFromString(data.contents, 'text/html');

    // Extract metadata
    const title = doc.querySelector('title')?.textContent || url;
    const description = doc.querySelector('meta[name="description"]')?.getAttribute('content') ||
                       doc.querySelector('meta[property="og:description"]')?.getAttribute('content') ||
                       'No description available';

    // Extract main content
    const mainContent = extractMainContent(doc);
    
    return {
      id: generateId(),
      name: title,
      type: 'url',
      content: `${title}\n\n${description}\n\n${mainContent}`,
      metadata: {
        size: data.contents.length,
        lastModified: Date.now(),
        url: url
      },
      createdAt: Timestamp.now()
    };
  } catch (error) {
    console.error('Error processing URL:', error);
    throw new Error('Failed to process URL content');
  }
}

function extractMainContent(doc: Document): string {
  // Try to find main content area using common selectors
  const contentSelectors = [
    'article',
    'main',
    '[role="main"]',
    '.main-content',
    '#main-content',
    '.content',
    '.post-content',
    '.article-content'
  ];

  let content = '';
  
  // Try each selector
  for (const selector of contentSelectors) {
    const element = doc.querySelector(selector);
    if (element) {
      // Remove script tags and other unwanted elements
      Array.from(element.getElementsByTagName('script')).forEach(el => el.remove());
      Array.from(element.getElementsByTagName('style')).forEach(el => el.remove());
      content = element.textContent || '';
      break;
    }
  }

  // Fallback to body if no main content found
  if (!content) {
    const body = doc.body;
    if (body) {
      Array.from(body.getElementsByTagName('script')).forEach(el => el.remove());
      Array.from(body.getElementsByTagName('style')).forEach(el => el.remove());
      content = body.textContent || '';
    }
  }

  // Clean and format content
  return content
    .replace(/\s+/g, ' ')
    .replace(/\n+/g, '\n')
    .trim()
    .slice(0, 5000); // Limit content length
}