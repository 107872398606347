// @ts-nocheck
import { useState } from 'react';
import { useChatListStore } from '../stores/chatListStore';
import { useRequirementsStore } from '../stores/requirementsStore';
import { useCanvasStore } from '../stores/canvasStore';
import { generateCanvasName } from '../services/ai/canvasName';
import { generateSkillTree } from '../services/ai/skillTree';
import { sendMessageToOpenAI } from '../services/openai';
import { processUrl } from '../services/urlProcessing';
import { generateId } from '../utils';
import type { ChatHookResult } from '../types/chat';

export function useChat(): ChatHookResult {
  const { activeChat, updateChat } = useChatListStore();
  const { addRequirement } = useRequirementsStore();
  const { setTitle } = useCanvasStore();
  const [inputValue, setInputValue] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSendMessage = async (content: string, files?: File[]) => {
    if (!content.trim() && (!files || files.length === 0)) return;
    if (!activeChat) return;

    setLoading(true);

    try {
      const messageId = generateId();
      let processedContent = content;

      // Process URLs in content
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      const urls = content.match(urlRegex);

      if (urls) {
        for (const url of urls) {
          try {
            const processedUrl = await processUrl(url);
            const urlId = generateId();
            processedContent = processedContent.replace(url, `[${processedUrl.name}:${urlId}]`);
            
            const requirement = {
              id: urlId,
              category: 'document' as const,
              content: processedUrl.content.slice(0, 200) + '...',
              timestamp: new Date(),
              document: {
                name: processedUrl.name,
                preview: processedUrl.content.slice(0, 200) + '...',
                type: 'url',
                metadata: {
                  url: url,
                  ...processedUrl.metadata
                }
              }
            };

            addRequirement(requirement);
            updateChat(activeChat, chat => ({
              ...chat,
              requirements: [...(chat.requirements || []), requirement]
            }));
          } catch (error) {
            console.error('Error processing URL:', error);
          }
        }
      }

      // Add user message as requirement
      const messageRequirement = {
        id: generateId(),
        category: 'content' as const,
        content: content.trim(),
        timestamp: new Date()
      };

      addRequirement(messageRequirement);
      updateChat(activeChat, chat => ({
        ...chat,
        requirements: [...(chat.requirements || []), messageRequirement]
      }));

      // Add user message
      const userMessage = {
        id: messageId,
        content: processedContent,
        role: 'user',
        timestamp: new Date(),
        files: files?.map(file => ({
          name: file.name,
          type: file.type,
          size: file.size
        }))
      };

      // Update chat with user message
      updateChat(activeChat, chat => ({
        ...chat,
        messages: [...(chat.messages || []), userMessage]
      }));

      // Process files
      let fileContents = '';
      if (files && files.length > 0) {
        for (const file of files) {
          const processedFile = await processFile(file);
          fileContents += `\n\nFile Content (${file.name}):\n${processedFile.content}`;
          
          const requirement = {
            id: generateId(),
            category: 'document' as const,
            content: processedFile.content.slice(0, 200) + '...',
            timestamp: new Date(),
            document: {
              name: file.name,
              preview: processedFile.content.slice(0, 200) + '...',
              type: file.type
            }
          };

          addRequirement(requirement);
          updateChat(activeChat, chat => ({
            ...chat,
            requirements: [...(chat.requirements || []), requirement]
          }));
        }
      }

      // Add AI message placeholder
      const aiMessageId = generateId();
      const aiMessage = {
        id: aiMessageId,
        content: '',
        role: 'assistant',
        timestamp: new Date(),
        isStreaming: true
      };

      updateChat(activeChat, chat => ({
        ...chat,
        messages: [...(chat.messages || []), aiMessage]
      }));

      setInputValue('');

      // Run all AI operations concurrently
      const requirements = useRequirementsStore.getState().requirements;
      const context = [
        ...requirements.map(req => `${req.category}: ${req.content}`),
        `Latest message: ${content}`
      ];

      const [_, skillTreeResult] = await Promise.all([
        // Generate canvas name
        generateCanvasName(context).then(newTitle => {
          setTitle(newTitle);
          updateChat(activeChat, chat => ({
            ...chat,
            title: newTitle
          }));
        }),

        // Generate skill tree
        generateSkillTree([...requirements.map(req => ({
          id: req.id,
          role: 'user' as const,
          content: req.content,
          timestamp: req.timestamp,
          model: 'gpt-4-0125-preview'
        }))], 'gpt-4-0125-preview').then(skillTree => {
          updateChat(activeChat, chat => ({
            ...chat,
            skillTree
          }));
          return skillTree;
        }),

        // Send to OpenAI and update chat with response
        sendMessageToOpenAI(processedContent + fileContents, {
          onContent: (content) => {
            updateChat(activeChat, chat => ({
              ...chat,
              messages: chat.messages.map(msg =>
                msg.id === aiMessageId ? { ...msg, content } : msg
              )
            }));
          },
          onSuggestions: (suggestions) => {
            updateChat(activeChat, chat => ({
              ...chat,
              messages: chat.messages.map(msg =>
                msg.id === aiMessageId ? { ...msg, suggestions } : msg
              )
            }));
          }
        })
      ]);

      console.log('All AI operations completed');

    } catch (error) {
      console.error('Failed to send message:', error);
    } finally {
      setLoading(false);
    }
  };

  return {
    inputValue,
    setInputValue,
    sendMessage: handleSendMessage,
    loading
  };
}